import React from "react";
import { Link, Route } from "react-router-dom";
import * as firebase from 'firebase';
import MDSpinner from 'react-md-spinner';
import CurrencyInput from 'react-currency-masked-input'


class AddPayment extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
            foreseen: "",
            effective: "",
            working: false,
            type: "cash",
            amount: 0,
            status: "pending"
        };
        this.close = this.close.bind(this);
        this.delete = this.delete.bind(this);
        this.save = this.save.bind(this);
    }
    componentDidMount(){
        //console.log(this.props)
        if(this.props.id !== "new"){
            this.setState({
                type: this.props.project.payment.installments[this.props.id].type,
                amount: this.props.project.payment.installments[this.props.id].amount,
                status: this.props.project.payment.installments[this.props.id].status,
                foreseen: this.props.project.payment.installments[this.props.id].foreseen,
                effective: this.props.project.payment.installments[this.props.id].effective,
            })
        } else {
            this.setState({
                amount: 10000,
            })
        }
    }
    save(){
        this.setState({
            working: true
        })
        let payload;
        if(this.props.id === "new"){
            let installments = this.props.project.payment !== null ? this.props.project.payment.installments : [];
            installments.push({
                "foreseen": this.state.foreseen,
                "amount": parseInt(this.state.amount.toString().replace(".", "")),
                "status": this.state.status,
                "registry": null,
                "type": this.state.type
            });
            payload = {
                "payment": {
                    "installments": installments
                },
                "updated": new Date()
            }
        } else {
            let installments = this.props.project.payment !== null ? this.props.project.payment.installments : []
            installments[this.props.id] = {
                "foreseen": this.state.foreseen,
                "effective": this.state.effective,
                "amount": parseInt(this.state.amount.toString().replace(".", "")),
                "status": this.state.status,
                "registry": null,
                "type": this.state.type
            };
            payload = {
                "payment": {
                    "installments": installments
                },
                "updated": new Date()
            }
        }
        firebase.firestore().collection('projects').doc(this.props.project.id).set(payload, { merge: true }).then((returned) => {
            firebase.firestore().collection('messages').add({ "project": this.props.project.id, "title": this.props.project.client.name, "message": this.props.profile.name + " " + (this.props.id === "new" ? "adicionou" : "alterou") + " dados de pagamento", "created": new Date() })
            this.setState({
                working: false
            }, () => {
                this.close();
            })
        }).catch((e) => {
            console.error(e);
            alert("Ocorreu um erro ao adicionar novo pagamento.")
            this.setState({
                working: false
            })
        })
    }
    delete(){
        if(window.confirm("Deseja deletar este pagamento?")){
            this.setState({
                working: true
            }, () => {
                let payload;
                let installments = this.props.project.payment.installments;
                installments.splice(this.props.id, 1);
                payload = {
                    "payment": {
                        "installments": installments
                    },
                    "updated": new Date()
                };
                firebase.firestore().collection('projects').doc(this.props.project.id).set(payload, { merge: true }).then((returned) => {
                    firebase.firestore().collection('messages').add({ "project": this.props.project.id, "title": this.props.project.client.name, "message": this.props.profile.name + " deletou um item de pagamento", "created": new Date() })
                    this.setState({
                        working: false
                    }, () => {
                        this.close();
                    })
                }).catch((e) => {
                    console.error(e);
                    alert("Ocorreu um erro ao deletar pagamento.")
                    this.setState({
                        working: false
                    })
                })
            })
        }
        
    }
    close(){
        this.props.close();
    }
    getEffectiveDefaultDate(){
        return new Date().getFullYear() + "-" + (((new Date().getMonth() + 1) < 10 ? "0" : "") + (new Date().getMonth() + 1)) + "-" + (new Date().getDate() < 10 ? "0" : "") + new Date().getDate() 
    }
	render() {
        //console.log((this.state.amount / 100).toFixed(2));
		return (
			<div style={styles.body}>
                <div style={styles.header}>
                    <h3 style={styles.title}>Pagamento</h3>
                </div>
                <form style={styles.form}>
                    {this.state.amount != 0 ? <div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Tipo</label>
                            <select style={styles.select} value={this.state.type} onChange={(e) => this.setState({ type: e.target.value })}>
                                <option value="cash">Dinheiro</option>
                                <option value="check">Cheque</option>
                                <option value="debit">Débito</option>
                                <option value="credit">Crédito</option>
                                <option value="transfer">Transferência</option>
                                <option value="billet">Boleto</option>
                            </select>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Valor</label>
                            <CurrencyInput placeholder="1500.00" style={styles.input} defaultValue={(this.state.amount / 100).toFixed(2)} onChange={(e) => this.setState({ amount: e.target.value })}/>
                        </div>
                    </div> : null}
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Vencimento previsto</label>
                        <input type="date" placeholder="12/01/2019" style={styles.input} disabled={this.props.id === "new" ? false : true} defaultValue={this.state.foreseen} onChange={(e) => this.setState({ foreseen: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Status</label>
                        <select style={styles.select} value={this.state.status} onChange={(e) => this.setState({ status: e.target.value, effective: e.target.value === "paid" ? this.getEffectiveDefaultDate() : "" })}>
                            <option value="pending">Pendente</option>
                            <option value="paid">Pago</option>
                        </select>
                    </div>
                    {this.state.status === "paid" ? <div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Pagamento efetivo</label>
                            <input type="date" placeholder="12/01/2019" style={styles.input} defaultValue={this.state.effective} onChange={(e) => this.setState({ effective: e.target.value })}/>
                        </div>
                    </div> : null}
                    <hr/>
                    <div style={styles.actions}>
                        {this.props.id != "new" && !this.state.working ? <button style={{...styles.button, ...styles.delete}} type="button" onClick={this.delete}>DELETAR</button> : null}
                        {this.state.working ? <MDSpinner style={{ marginLeft: "15px" }} singleColor="#32e100" size="24"/> : <button style={styles.button} type="button" onClick={this.save}>{this.props.id === "new" ? "ADICIONAR" : "SALVAR"}</button>}
                    </div>
                </form>
            </div>
		);
	}
}

const styles = {
	body: {
        
    },
    header: {
        height: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        backgroundColor: "#CCCCCC",
        color: "#999999",
    },
    title: {
        fontSize: "16px",
        textTransform: "uppercase",
        fontWeight: "300",
        margin: "0px",
        color: "#FFFFFF"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "50px"
    },
    field: {
        position: "relative",
    },
    label: {
        position: "absolute",
        top: "-7px",
        left: "10px",
        fontSize: "10px",
        padding: "0px 5px",
        backgroundColor: "#FFFFFF",
        color: "#CCCCCC",
        textTransform: "uppercase"
    },
    input: {
        border: "none",
        padding: "15px",
        paddingBottom: "20px",
        width: "100%"
    },
    select: {
        border: "none",
        margin: "15px 8px",
        paddingBottom: "20px",
        width: "calc(100% - 30px)",
        backgroundColor: "#FFFFFF"
    },
    actions: {
        padding: "15px",
        textAlign: "right"
    },
    button: {
		backgroundColor: "rgba(50, 225, 0, 1.0)",
		color: "#FFF",
		padding: "5px 13px",
		borderRadius: "22px",
        fontSize: "12px",
        fontWeight: 300,
        border: "none",
        marginLeft: "15px"
    },
    alt: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		color: "#999999",
    },
    delete: {
		backgroundColor: "#ff6868",
		color: "#FFFFFF",
    },
}

export default AddPayment;

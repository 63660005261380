import React from "react";
import { Link, Route } from "react-router-dom";
import { FirestoreCollection } from 'react-firestore';
import MDSpinner from 'react-md-spinner';
import Modal from 'react-modal';
import User from './User';
import AddUser from './AddUser.js';
import EditUser from './EditUser.js';
import EditSubsidiary from '../subsidiaries/EditSubsidiary.js';

class Users extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			supplier: false,
			user: false, 
			modal: null,
			search: "",
			filter: ""
		};
	}

	renderUser(isLoading, error, data){
		if(isLoading){
			return <div style={styles.loading}><MDSpinner style={{ marginLeft: "15px" }} singleColor="#999999" size="24"/></div>
		} else {
			return ((this.state.search.length > 0 ? data.filter((i) => i.name.toLowerCase().search(this.state.search.toLowerCase()) >= 0) : data).map((user, i) => {
				return <Link to={"/users/" + user.id} style={{...styles.item, ...(this.props.location.pathname === ("/users/" + user.id) ? styles.active : {})}} key={i}>
					<div style={{...styles.avatar, ...{ backgroundImage: 'url(' + (user.photo ? user.photo : "https://ui-avatars.com/api/?name=" + encodeURI(user.name)) + '&size=80)'}}}></div>
					<div>
						<p style={styles.name}>{user.name}</p>
						{user.role ? <p style={{...styles.name, ...{ color: "#999999" }}}>{this.renderBadge(user.role)}</p> : null}
					</div>
				</Link>
			}));
		}
	}
	render() {
		//console.log(this.state.search)
		return (
			<div style={{...styles.body, ...(this.props.width < 767 ? { width: "100%" } : { width: this.props.width - 71 })}}>
				<div style={{...styles.list, ...(this.props.width < 767 ? { width: this.props.width, height: this.props.height - 70 } : { width: "300px", borderRight: "1px solid #EDEDED", height: this.props.height })}}>
					<div style={styles.filter}>
						<Link to={"/users"} onClick={() => this.setState({ filter: this.state.filter === "admin" ? "" : "admin", search: "" })} style={styles.bullet}>{this.state.filter === "admin" ? <i className="material-icons" style={styles.toggleicon}>close</i> : null} ADMIN</Link>
						<Link to={"/users"} onClick={() => this.setState({ filter: this.state.filter === "staff" ? "" : "staff", search: "" })} style={styles.bullet}>{this.state.filter === "staff" ? <i className="material-icons" style={styles.toggleicon}>close</i> : null} STAFF</Link>
						<Link to={"/users"} onClick={() => this.setState({ filter: this.state.filter === "supplier" ? "" : "supplier", search: "" })} style={styles.bullet}>{this.state.filter === "supplier" ? <i className="material-icons" style={styles.toggleicon}>close</i> : null} FORNE</Link>
					</div>
					<FirestoreCollection
						path="profiles"
						filter={this.state.filter !== "" ? ["role", "==", this.state.filter] : null}
						render={({isLoading, error, data}) => this.renderUser(isLoading, error, data)}
					/>
					<div style={{...styles.search, ...(this.props.width < 767 ? {left: "0px", right: "0px" } : {})}}>
						<input type="text" style={styles.input} value={this.state.search} placeholder="Buscar" onChange={(e) => this.setState({ search: e.target.value })}/>
						{this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <div className="dropdown">
							<Link to="/users/edit/new" style={styles.addaction} className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i className="material-icons">add</i>
							</Link>
							<div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
								<a className="dropdown-item" onClick={() => this.setState({ supplier: true })}>Fornecedor</a>
								<a className="dropdown-item" onClick={() => this.setState({ user: true })}>Colaborador</a>
							</div> 
						</div> : null}
					</div>
				</div>
				<Route path="/users/:id" render={(props) => {
					if(props.match.params.id == "edit"){
						return null;
					}
					return <User width={this.props.width} height={this.props.height} profile={this.props.profile} {...props}/>
				}} />
				<Modal isOpen={this.state.supplier} style={styles.modal} onRequestClose={() => this.setState({ supplier: false })}>
					<EditUser close={() => this.setState({ supplier: false })} id="new" user={this.props.user} profile={this.props.profile}/>
				</Modal>
				<Modal isOpen={this.state.user} style={styles.modal} onRequestClose={() => this.setState({ user: false })}>
					<AddUser close={() => this.setState({ user: false })}/>
				</Modal>
            </div>
		);
	}

	renderBadge(role){
        switch(role){
            case "superadmin":
            return <span style={styles.role}>SUPER ADMIN</span>

            case "admin":
            return <span style={styles.role}>ADMIN</span>

            case "staff":
            return <span style={styles.role}>STAFF</span>

            case "supplier":
            return <span style={styles.role}>FORNECEDOR</span>

            default:
            return <span style={styles.role}>{role.toUpperCase()}</span>
        }
    }
}

const styles = {
	body: {
		display: "flex",
		flexDirection: "row",
	},
	search: {
		position: "fixed",
		bottom: "0px",
		//left: "0px",
		//right: "0px",
		height: "70px",
		padding: "15px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		fontWeight: 300,
		backgroundColor: "#f8f8f8"
	},
	filter: {
		position: "absolute",
		top: "5px",
		left: "0px",
		right: "0px",
		height: "50px",
		padding: "15px 15px 10px 15px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		fontWeight: 300,
		backgroundColor: "#f8f8f8"
	},
	bullet: {
		backgroundColor: "transparent",
		borderRadius: "25px",
		border: "1px solid #555555",
		color: "#555555",
		padding: "3px 15px"
	},
	toggleicon: {
		fontSize: "15px",
    	position: "relative",
    	top: "3px"
	},
	input: {
		padding: "10px 20px",
		marginRight: "15px",
		backgroundColor: "#DDDDDD",
		border: "none",
		borderRadius: "25px",
		color: "#555555",
		flex: 1
	},
	list: {
		position: "relative",
		overflowY: "auto",
		padding: "60px 0px 70px 0px",
	},
	item: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		borderBottom: "1px solid rgba(0,0,0,.05)",
		//borderRadius: "7px",
		//margin: "15px 15px 0px 15px",
		padding: "15px",
		fontWeight: 300,
		color: "#999999"
	},
	active: {
		backgroundColor: "#FFFFFF",
		color: "#555555"
	},
	role: {
		fontSize: "12px"
	},
	loading: {
		padding: "30px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	avatar: {
		width: "40px",
		height: "40px",
		borderRadius: "20px",
        backgroundColor: "#DDDDDD",
		backgroundSize: "cover",
		backgroundPosition: "center",
		marginRight: "15px"
	},
	name: {
		margin: "0px"
	},
	addaction: {
		marginRight: "10px",
		display: 'flex',
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	},
	modal: {
		overlay: {
            backgroundColor: "rgba(0,0,0,.5)",
            overflowY: "auto"
		},
		content: {
            margin: "0px auto",
            padding: "0px",
            maxWidth: "400px",
            minWidth: "300px",
		}
	}
}

export default Users;

import React from "react";
import { Link } from "react-router-dom";
import EditProfile from '../routes/users/EditProfile.js'
import EditPassword from '../routes/users/EditPassword.js'
import Modal from 'react-modal';

class SideBar extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			modal: false
		};
	}
	render() {
		return (
			<div style={this.props.width < 767 ? styles.mobile : styles.desktop}>
                {this.props.width > 767 && this.props.profile.role === "superadmin" ? <Link to="/subsidiaries" style={{...styles.padding, ...styles.item, ...(this.props.location.pathname.indexOf("/subsidiaries") >= 0 ? styles.active : {})}} title="Filiais">
					<i className="material-icons">home</i>	
				</Link> : <a></a>}
				<div style={this.props.width < 767 ? styles.menu : {}}>
					<Link to="/projects" style={{...styles.padding, ...styles.item, ...(this.props.location.pathname.indexOf("/projects") >= 0 ? styles.active : {})}} title="Projetos">
						<i className="material-icons">work</i>	
						{/*<span style={styles.badge}>9</span>*/}
					</Link>
					
					{this.props.profile.role !== "staff" ? <Link to="/items" style={{...styles.padding, ...styles.item, ...(this.props.location.pathname.indexOf("/items") >= 0 ? styles.active : {})}} title="Itens">
						<i className="material-icons">local_offer</i>	
						{/*<span style={styles.badge}>9</span>*/}
					</Link> : null}
					
					{this.props.profile.role !== "staff" ? <Link to="/finance" style={{...styles.padding, ...styles.item, ...(this.props.location.pathname.indexOf("/finance") >= 0 ? styles.active : {})}} title="Financeiro">
						<i className="material-icons">pie_chart</i>	
						{/*<span style={styles.badge}>9</span>*/}
					</Link> : null}
					<Link to="/users" style={{...styles.padding, ...styles.item, ...(this.props.location.pathname.indexOf("/users") >= 0 ? styles.active : {})}} title="Usuários">
						<i className="material-icons">people</i>	
						{/*<span style={styles.badge}>9</span>*/}
					</Link>
				</div>
				<a onClick={() => this.setState({ modal: true })} style={{...styles.padding, ...styles.user}}>
					<div>
						<div style={{...styles.avatar, ...{ backgroundImage: 'url(' + this.props.user.photoURL + ')'}}}></div>
					</div>
				</a>
				<Modal isOpen={this.state.modal} style={styles.modal} onRequestClose={() => this.setState({ modal: false })}>
					<EditProfile user={this.props.user} profile={this.props.profile} close={() => this.setState({ modal: false })} password={() => this.setState({ modal: false, password: true })} update={() => this.props.update()}/>
				</Modal>
				<Modal isOpen={this.state.password} style={styles.modal} onRequestClose={() => this.setState({ password: false })}>
					<EditPassword user={this.props.user} profile={this.props.profile} close={() => this.setState({ password: false })}/>
				</Modal>
            </div>
		);
	}
}

const styles = {
	desktop: {
		display: "flex",
        flexDirection: "column",
        width: "70px",
		backgroundColor: "#FFFFFF",
		justifyContent: "space-between",
		borderRight: "1px solid #EDEDED",
	},
	mobile: {
		display: "flex",
        flexDirection: "row",
        height: "70px",
		backgroundColor: "#FFFFFF",
		justifyContent: "center",
		borderRight: "1px solid #EDEDED",
		position: "fixed",
		top: "0px",
		left: "0px",
		right: "0px",
		boxShadow: "0 1px 5px 1px rgba(0,0,0,0.1)",
		//zIndex: 10
	},
	padding: {
		padding: "15px"
	},
	logo: { 
		textAlign: "center",
		padding: "30px 0px"
	},
	menu: {
		display: "flex",
        flexDirection: "row",
	},
	item: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
		width: "70px",
		height: "70px",
		color: "#CCCCCC",
		fontSize: "16px",
		fontWeight: "500",
		position: "relative"
	},
	active: {
		//borderLeft: "5px solid rgba(50, 225, 0, 0.5)",
		color: "rgb(0, 0, 0)"
	},
	badge: {
		backgroundColor: "rgba(50, 225, 0, 1.0)",
		color: "#FFF",
		padding: "0px 6px",
		borderRadius: "14px",
		position: "absolute",
		top: "5px",
		right: "5px",
		fontSize: "14px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	user: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		fontWeight: 300
	},
	avatar: {
		width: "40px",
		height: "40px",
		borderRadius: "20px",
		backgroundColor: "#DDDDDD",
		backgroundSize: "cover",
		backgroundPosition: "center",
		marginRight: "15px"
	},
	modal: {
		overlay: {
            backgroundColor: "rgba(0,0,0,.5)",
            overflowY: "auto"
		},
		content: {
            margin: "0px auto",
            padding: "0px",
            maxWidth: "400px",
			minWidth: "300px",
			overflow: "hidden"
		}
	}
}

export default SideBar;

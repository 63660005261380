import React from "react";

class AddUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 

        };
        this.close = this.close.bind(this);
    }

    close(){
        this.props.close();
    }

	render() {
		return (
			<div style={styles.body}>
                <div style={styles.header}>
                    <h3 style={styles.title}>Adicionar Colaborador</h3>
                </div>
                <form style={styles.form}>
                    <div style={styles.field}>
                        <p style={{ padding: "15px" }}>Compartilhe o link abaixo para acesso ao formulário de cadastro para usuários do sistema Hommax:</p>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Link</label>
                        <input type="text" style={styles.input} value={"https://app.hommax.com.br/"} readOnly/>
                    </div>
                    <hr/>
                    
                    <div style={styles.actions}>
                        <button style={{...styles.button, ...styles.alt}} type="button" onClick={this.close}>CANCELAR</button>
                    </div>
                </form>
            </div>
		);
	}
}

const styles = {
	body: {
        
    },
    header: {
        height: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        backgroundColor: "#CCCCCC",
        color: "#999999",
    },
    title: {
        fontSize: "16px",
        textTransform: "uppercase",
        fontWeight: "300",
        margin: "0px",
        color: "#FFFFFF"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "50px"
    },
    field: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "60px"
    },
    label: {
        position: "absolute",
        top: "-7px",
        left: "10px",
        fontSize: "10px",
        padding: "0px 5px",
        backgroundColor: "#FFFFFF",
        color: "#CCCCCC",
        textTransform: "uppercase"
    },
    input: {
        border: "none",
        padding: "15px",
        paddingBottom: "20px",
        width: "100%"
    },
    select: {
        border: "none",
        margin: "15px 8px",
        paddingBottom: "20px",
        width: "calc(100% - 30px)",
        backgroundColor: "#FFFFFF"
    },
    actions: {
        padding: "15px",
        textAlign: "right"
    },
    button: {
		backgroundColor: "rgba(50, 225, 0, 1.0)",
		color: "#FFF",
		padding: "5px 13px",
		borderRadius: "22px",
        fontSize: "12px",
        fontWeight: 300,
        border: "none",
        marginLeft: "15px"
    },
    alt: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		color: "#999999",
    },
    refresh: {
        border: "none",
        backgroundColor: "transparent",
        height: "30px"
    }
}

export default AddUser;

import React from "react";
import { Link, Route } from "react-router-dom";
import { FirestoreCollection } from 'react-firestore';
import MDSpinner from 'react-md-spinner';
import Registry from "./Registry";
import AddRegistry from "./AddRegistry";
import Modal from 'react-modal';

class Registries extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			filter: "",
			registry: null
		};
	}
	renderUser(isLoading, error, data){
		if(isLoading){
			return <div style={styles.loading}><MDSpinner style={{ marginLeft: "15px" }} singleColor="#999999" size="24"/></div>
		} else {
			return  (this.state.filter != "" ? data.filter((i) => i.flow == this.state.filter) : data).map((registry, i) => {
				return <Link to={"/finance/" + registry.id} style={{...styles.item, ...(this.props.location.pathname === ("/finance/" + registry.id) ? styles.active : {})}} key={i}>
					<div>
						{(registry.amount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
					</div>
					{registry.flow === "income" ? <i className="material-icons" style={styles.income}>arrow_downward</i> : <i className="material-icons" style={styles.outcome}>arrow_upward</i>}
				</Link>
			});
		}
	}
	render() {
		return (
			<div style={{...styles.body, ...(this.props.width < 767 ? { width: "100%" } : { width: this.props.width - 71 })}}>
				<div style={this.props.width < 767 ? { width: this.props.width } : { width: "300px", borderRight: "1px solid #EDEDED" }}>
					<div style={styles.search}>
						<span>
							<a onClick={() => this.setState({ filter: "outcome" })} style={styles.outcome}><i className="material-icons">arrow_upward</i></a>
							<a onClick={() => this.setState({ filter: "income" })} style={{...styles.income, ...{ marginLeft: "15px" }}}><i className="material-icons">arrow_downward</i></a>
						</span>
						{this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <a onClick={() => this.setState({ registry: "income" })}><i className="material-icons">add</i></a> : null}
					</div>
					<FirestoreCollection
						path="registries"
						filter={this.props.profile.role !== "superadmin" ? ["subsidiary", "==", this.props.profile.subsidiary.id] : null}
						//sort="created:desc"
						render={({isLoading, error, data}) => this.renderUser(isLoading, error, data)}
					/>
				</div>
				<Route path="/finance/:id" render={(props) => {
					if(props.match.params.id == "edit"){
						return null;
					}
					return <Registry width={this.props.width} height={this.props.height} profile={this.props.profile} {...props}/>
				}} />
				<Modal isOpen={this.state.registry ? true : false} style={styles.modal} onRequestClose={() => this.setState({ registry: null })}>
					<AddRegistry close={() => this.setState({ registry: null })} registry={this.state.registry} profile={this.props.profile}/>
				</Modal>
            </div>
		);
	}
}

const styles = {
	body: {
		display: "flex",
		flexDirection: "row",
	},
	search: {
		height: "58px",
		margin: "15px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		
		alignItems: "center",
		fontWeight: 300
	},
	input: {
		padding: "10px 20px",
		marginRight: "15px",
		backgroundColor: "#DDDDDD",
		border: "none",
		borderRadius: "25px",
		color: "#555555",
		flex: 1
	},
	item: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		borderBottom: "1px solid rgba(0,0,0,.05)",
		//borderRadius: "7px",
		//margin: "15px 15px 0px 15px",
		height: "55px",
		padding: "0px 15px",
		fontWeight: 300,
		color: "#999999"
	},
	active: {
		backgroundColor: "#FFFFFF",
		color: "#555555"
	},
	badge: {
		backgroundColor: "#32e100FF",
		color: "#FFF",
		padding: "5px 10px",
		borderRadius: "16px",
		fontSize: "12px"
	},
	outcome: {
		color: "#ff4949"
	},
	income: {
		color: "#32e100"
	},
	modal: {
		overlay: {
            backgroundColor: "rgba(0,0,0,.5)",
            overflowY: "auto"
		},
		content: {
            margin: "0px auto",
            padding: "0px",
            maxWidth: "400px",
            minWidth: "300px",
		}
	},
	loading: {
		padding: "30px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
}

export default Registries;

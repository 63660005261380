import React from "react";
import { Link, Route } from "react-router-dom";
import * as firebase from 'firebase';
import MDSpinner from 'react-md-spinner';

class Email extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
            working: false,
            download: false,
            email: this.props.project.client ? this.props.project.client.email ? this.props.project.client.email : "" : "",
            subject: "",
            text: "",
            subsidiary: null
        };
        this.send = this.send.bind(this)
    }
    componentDidMount(){
        firebase.firestore().collection('subsidiaries').doc(this.props.project.subsidiary.id).get().then((subsidiary) => {
            this.setState({
                subsidiary: subsidiary.data()
            })
        });
    }
    send(){
        if(this.state.email != "" && this.state.subject != "" && this.state.text != ""){
            let that = this;
            this.setState({
                working: true
            }, () => {
                var email = firebase.functions().httpsCallable('email');
                email({ 
                    email: this.state.email,
                    subject: this.state.subject,
                    text: this.state.text.replace(/\r?\n/g, '<br/>'),
                    project: this.state.download ? this.props.project.id : null,
                    name: this.props.profile.name,
                    from: this.props.profile.email,
                    photo: this.props.profile.photo,
                    address: this.state.subsidiary.location.address + "<br/>" + this.state.subsidiary.location.city + " - " + this.state.subsidiary.location.state + "<br/>" + this.state.subsidiary.phone
                }).then(function(result) {
                    that.props.close()
                }).catch(function(error) {
                    console.error(error)
                    that.setState({
                        working: false
                    })
                    window.alert("Ocorreu um erro ao enviar email.")
                });	
            })
        } else {
            window.alert("Preencha todos os campos para continuar.")
        }
    }
	render() {
		return (
			<div style={styles.body}>
                <div style={styles.header}>
                    <h3 style={styles.title}>E-mail</h3>
                </div>
                <form style={styles.form}>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>E-mail</label>
                        <input type="email" placeholder="nome@email.com" value={this.state.email} style={styles.input} onChange={(e) => this.setState({ email: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Assunto</label>
                        <input type="email" placeholder="Aqui está sua proposta" value={this.state.subject} style={styles.input} onChange={(e) => this.setState({ subject: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Mensagem</label>
                        <textarea style={styles.input} placeholder="Corpo do e-mail" rows="5" value={this.state.text} onChange={(e) => this.setState({ text: e.target.value })}></textarea>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Download</label>
                        <p style={styles.checkbox}><input type="checkbox" value={this.state.download} onChange={(e) => this.setState({ download: e.target.value })}/> <span>Incluir download do documento?</span></p>
                    </div>
                    <hr/>
                    <div style={styles.actions}>
                    {this.state.working ? <MDSpinner style={{ marginLeft: "15px" }} singleColor="#32e100" size="24"/> : this.state.subsidiary ? <button style={styles.button} type="button" onClick={this.send}>ENVIAR</button> : null}
                    </div>
                </form>
            </div>
		);
	}
}

const styles = {
	body: {
        
    },
    header: {
        height: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        backgroundColor: "#CCCCCC",
        color: "#999999",
    },
    title: {
        fontSize: "16px",
        textTransform: "uppercase",
        fontWeight: "300",
        margin: "0px",
        color: "#FFFFFF"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "50px"
    },
    field: {
        position: "relative",
    },
    label: {
        position: "absolute",
        top: "-7px",
        left: "10px",
        fontSize: "10px",
        padding: "0px 5px",
        backgroundColor: "#FFFFFF",
        color: "#CCCCCC",
        textTransform: "uppercase"
    },
    input: {
        border: "none",
        padding: "15px",
        paddingBottom: "20px",
        width: "100%"
    },
    checkbox: {
        padding: "18px",
        margin: "0px"
    },
    actions: {
        padding: "15px",
        textAlign: "right"
    },
    button: {
		backgroundColor: "rgba(50, 225, 0, 1.0)",
		color: "#FFF",
		padding: "5px 13px",
		borderRadius: "22px",
        fontSize: "12px",
        fontWeight: 300,
        border: "none",
        marginLeft: "15px"
    },
    alt: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		color: "#999999",
	},
}

export default Email;

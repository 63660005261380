import React from "react";
import { Link, Route } from "react-router-dom";
import * as firebase from 'firebase';
import MDSpinner from 'react-md-spinner';


class EditFolder extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
            working: false,
            name: "",
            files: []
        };
        this.add = this.add.bind(this);
        this.close = this.close.bind(this);
        this.delete = this.delete.bind(this);
        this.save = this.save.bind(this);
    }
    componentDidMount(){
        if(this.props.id !== "new"){
            this.setState({
                name: capitalize(this.props.id.replace(/-/g, ' ')),
                files: this.props.project.files ? this.props.project.files[this.props.id] : []
            })
        }
    }
    save(){
        this.setState({
            working: true
        })
        let payload;
        payload = {
            "files": {
                [this.state.name.replace(/\s+/g, '-').toLowerCase()]: this.state.files
            }
        };
        firebase.firestore().collection('projects').doc(this.props.project.id).set(payload, { merge: true }).then((returned) => {
            firebase.firestore().collection('messages').add({ "project": this.props.project.id, "title": this.props.project.client.name, "message": this.props.profile.name + " atualizou arquivos do projeto", "created": new Date() })
            this.setState({
                working: false
            }, () => {
                this.close(this.state.id === "new" ? this.state.name : null);
            })
        }).catch((e) => {
            console.error(e);
            alert("Ocorreu um erro ao salvar pasta.")
            this.setState({
                working: false
            })
        })
    }
    add(e){
        e.stopPropagation();
        e.preventDefault();
        let files = [];
        let that = this;
        const storage = firebase.storage();
        Array.from(e.target.files).map((file) => {
            let name = file.name + "@" + new Date().getTime();
            files.push({
                "id": name,
                "url": null,
                "name": file.name,
                "type": file.type
            });
            //
            let task = storage.ref().child("files/" + this.props.project.id + "/" + this.props.id + "/" + name).put(file); 
            task.then(() => {
                task.snapshot.ref.getDownloadURL().then(function(url) {
                    let update = that.state.files;
                    update.find((i) => i.id === name).url = url;
                    that.setState({
                        files: update
                    });
                }).catch((e) => {
                    console.error(e);
                    alert("Ocorreu um erro ao obter endereço do arquivo enviado.")
                    let update = that.state.files;
                    update.find((i) => i.id === name).url = "error";
                    that.setState({
                        files: update
                    });
                });
            }).catch((e) => {
                console.error(e);
                alert("Ocorreu um erro ao enviar arquivo.")
                let update = that.state.files;
                update.find((i) => i.id === name).url = "error";
                that.setState({
                    files: update
                });
            });
        });
        this.setState({ files });
    }
    delete(){
        if(window.confirm("Deseja deletar esta pasta?")){
            this.setState({
                working: true
            }, () => {
                let update = this.props.project.files;
                delete update[this.props.id]
                //console.log(update)
                let payload = {
                    "files": update
                };
                firebase.firestore().collection('projects').doc(this.props.project.id).set(payload, { merge: true }).then((returned) => {
                    this.setState({
                        working: false
                    }, () => {
                        this.close();
                    })
                }).catch((e) => {
                    console.error(e);
                    alert("Ocorreu um erro ao deletar pasta.")
                    this.setState({
                        working: false
                    })
                })
            })
        }
        
    }
    close(open){
        this.props.close(open);
    }
	render() {
        
		return (
			<div style={styles.body}>
                <div style={styles.header}>
                    <h3 style={styles.title}>Pasta</h3>
                </div>
                <form style={styles.form}>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Nome da pasta</label>
                        <input type="text" placeholder="Nome da pasta" style={styles.input} value={this.state.name !== "new" ? this.state.name : ""} disabled={this.props.id === "new" ? false : true} onChange={(e) => this.setState({ name: e.target.value })}/>
                    </div>
                    <hr/>
                    <div>
                        {this.state.files.map((item, i) => {
                            return <div key={i}>
                                <div style={styles.row}>
                                    <div style={{ alignItems: "center", flex: 0.1 }}>
                                        <div style={{...styles.preview, ...{ backgroundImage: item.url ? item.url !== "error" ? testUrl(item.name) ? "url(" + item.url + ")" : null : null : null }}}>{testUrl(item.name) ? null : <i className="material-icons">insert_drive_file</i> }</div>
                                    </div>
                                    <div style={{ alignItems: "center", flex: 0.7, width: "calc(100% - 140px)",  }}>
                                        <p className="nomargin" style={styles.itemtitle}>{item.name}</p>
                                    </div>
                                    <div style={{ textAlign: "right", flex: 0.2 }}>
                                        {item.url ? item.url !== "error" ? <a href={item.url} target="_blank" download><i className="material-icons">cloud_download</i></a> : <i className="material-icons">error_outline</i> : <MDSpinner style={{ marginLeft: "15px" }} singleColor="#000000" size="20"/>}
                                    </div>
                                </div>
                                <hr/>
                            </div>
                        })}
                    </div>
                    
                    {this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <div style={styles.actions}>
                        {this.props.id != "new" && !this.state.working ? <button style={{...styles.button, ...styles.delete}} type="button" onClick={this.delete}>DELETAR</button> : null}
                        {this.props.id != "new" ? <button style={styles.button} type="button" onClick={() => this.upload.click()}>ATUALIZAR</button> : null}
                        {this.state.working ? <MDSpinner style={{ marginLeft: "15px" }} singleColor="#32e100" size="24"/> : <button style={styles.button} type="button" onClick={this.save}>SALVAR</button>}
                    </div> : null}
                    <input type="file" ref={(ref) => this.upload = ref} style={{ display: 'none' }} onChange={this.add} multiple/>
                </form>
            </div>
		);
	}
}

const styles = {
	body: {
        
    },
    header: {
        height: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        backgroundColor: "#CCCCCC",
        color: "#999999",
    },
    title: {
        fontSize: "16px",
        textTransform: "uppercase",
        fontWeight: "300",
        margin: "0px",
        color: "#FFFFFF"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "50px"
    },
    field: {
        position: "relative",
    },
    label: {
        position: "absolute",
        top: "-7px",
        left: "10px",
        fontSize: "10px",
        padding: "0px 5px",
        backgroundColor: "#FFFFFF",
        color: "#CCCCCC",
        textTransform: "uppercase"
    },
    input: {
        border: "none",
        padding: "15px",
        paddingBottom: "20px",
        width: "100%"
    },
    select: {
        border: "none",
        margin: "15px 8px",
        paddingBottom: "20px",
        width: "calc(100% - 30px)",
        backgroundColor: "#FFFFFF"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        padding: "15px",
        alignItems: "center",
        justifyContent: "space-between"
    },
    itemtitle: { 
        
        height: "40px", 
        overflow: "hidden" 
    },
    preview: { 
        height: "40px", 
        width: "40px", 
        backgroundPosition: "center", 
        backgroundSize: "cover", 
        marginRight: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    },
    actions: {
        padding: "15px",
        paddingBottom: "0px",
        textAlign: "right"
    },
    button: {
		backgroundColor: "rgba(50, 225, 0, 1.0)",
		color: "#FFF",
		padding: "5px 13px",
		borderRadius: "22px",
        fontSize: "12px",
        fontWeight: 300,
        border: "none",
        marginLeft: "15px",
        marginBottom: "15px"
    },
    alt: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		color: "#999999",
    },
    delete: {
		backgroundColor: "#ff6868",
		color: "#FFFFFF",
    },
}

const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const testUrl = (string) => {
    let ext = string.split(".")[1];
    //console.log(ext)
    if(ext === "gif" || ext === "jpg" || ext === "png"){
        return true
    } else {
        return false
    }
    
}

export default EditFolder;

import React from "react";
import { Link, Route } from "react-router-dom";
import { FirestoreCollection } from 'react-firestore';
import * as firebase from 'firebase';
import MDSpinner from 'react-md-spinner';
import Countries from '../subsidiaries/Countries.js';
import CurrencyInput from 'react-currency-masked-input'

class EditMember extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
            working: false,
            search: "",
            
        };
        this.close = this.close.bind(this);
    }

    componentDidMount(){
        if(this.props.project.departments != null){
            if(this.props.project.departments[this.props.id] != null){
                this.setState({
                    text: this.props.project.departments[this.props.id].replace(/<br ?\/?>/g, "\n")
                });
            } 
        }
    }

    

    add(user){
        var payload = {
            "name": user.name,
            "photo": user.photo,
            "role": user.role
        }
        firebase.firestore().collection('projects').doc(this.props.project.id).collection('team').doc(user.id).set(payload, { merge: true }).then(() => {
            firebase.firestore().collection('messages').add({ "project": this.props.project.id, "title": this.props.project.client.name, "message": user.name + " foi adicionado ao projeto", "created": new Date() })
            this.setState({
                working: false
            }, () => {
                this.close();
            })
        }).catch((e) => {
            console.error(e);
            alert("Ocorreu um erro ao adicionar membro.")
            this.setState({
                working: false
            })
        })
    }

    close(){
        this.props.close();
    }

    renderProfile(isLoading, error, data){
        if(!isLoading){
			return (this.state.search != "" ? data.filter((i) => i.name.toLowerCase().search(this.state.search.toLowerCase()) >= 0) : data.filter((i) => i.role != "supplier")).map((item, i) => {
				return <a onClick={() => this.add(item)} style={styles.item} key={i}>
                    <span style={{...styles.avatar, ...{ backgroundImage: 'url(' + item.photo + ')' }}} title={item.name }></span>
                    <span>{item.name}</span>
                </a>
			});
		} else {
            return null
        }
    }

   
	render() {
		return (
			<div style={styles.body}>
                <div style={styles.header}>
                    <h3 style={styles.title}>Adicionar membro</h3>
                </div>
                {<form style={styles.form}>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Buscar</label>
                        <input type="text" placeholder="Nome do usuário" style={styles.input} value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })}/>
                    </div>
                    <hr/>
                    <div>
                        <FirestoreCollection
                            path={"profiles"}
                            render={({isLoading, error, data}) => this.renderProfile(isLoading, error, data)}
                        />
                    </div>
                    
                    <div style={styles.actions}>
                        <button style={{...styles.button, ...styles.alt}} type="button" onClick={this.close}>CANCELAR</button>
                        {this.state.working ? <MDSpinner style={{ marginLeft: "15px" }} singleColor="#32e100" size="24"/> : <button style={styles.button} onClick={this.state.id === "new" ? this.saveItem : this.save} type="button">SALVAR</button>}
                    </div>
                </form>}
            </div>
		);
    }
}

const styles = {
	body: {
        
    },
    header: {
        height: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        backgroundColor: "#CCCCCC",
        color: "#999999",
    },
    title: {
        fontSize: "16px",
        textTransform: "uppercase",
        fontWeight: "300",
        margin: "0px",
        color: "#FFFFFF"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "50px"
    },
    field: {
        position: "relative",
    },
    label: {
        position: "absolute",
        top: "-7px",
        left: "10px",
        fontSize: "10px",
        padding: "0px 5px",
        backgroundColor: "#FFFFFF",
        color: "#CCCCCC",
        textTransform: "uppercase"
    },
    input: {
        border: "none",
        padding: "15px",
        paddingBottom: "20px",
        width: "100%"
    },
    select: {
        border: "none",
        margin: "15px 8px",
        paddingBottom: "20px",
        width: "calc(100% - 30px)",
        backgroundColor: "#FFFFFF"
    },
    actions: {
        padding: "15px",
        textAlign: "right"
    },
    supplier: {
        
    },
    button: {
		backgroundColor: "rgba(50, 225, 0, 1.0)",
		color: "#FFF",
		padding: "5px 13px",
		borderRadius: "22px",
        fontSize: "12px",
        fontWeight: 300,
        border: "none",
        marginLeft: "15px"
    },
    alt: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		color: "#999999",
    },
    delete: {
		backgroundColor: "#ff6868",
		color: "#FFFFFF",
    },
    white: {
		backgroundColor: "#FFFFFF",
		color: "#999999",
    },
    item: {
        margin: "0px",
        padding: "15px"
    },
    avatar: {
        display: "block",
		width: "28px",
		height: "28px",
		borderRadius: "20px",
		backgroundColor: "#DDDDDD",
        backgroundSize: "cover",
        backgroundPosition: "center",
		marginRight: "15px",
    },
    item: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        padding: "5px 15px"
    }
}

export default EditMember;

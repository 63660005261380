import React from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import SideBar from "./SideBar";
import Projects from "./projects/Projects";
import Home from './home/Home';
import Items from './items/Items';
import Users from './users/Users';
import Registries from "./finance/Registries";
import Login from '../components/Login';
import MDSpinner from 'react-md-spinner';

import * as firebase from 'firebase';
import Subsidiaries from "./subsidiaries/Subsidiaries";




class App extends React.Component {
	constructor(props) {
		super(props);
		var that = this;
		this.state = { 
			width: 0, 
			height: 0,
			user: null,
			profile: null
		};
		this.scroll = React.createRef();
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		firebase.auth().onAuthStateChanged(function(user) {
			that._handleUserChange(user);
		});
		
	}

	_handleUserChange(user){
		if(user != null){
			this._getProfileAndUpdate(user)
		} else {
			this.setState({
				user: false
			})
		}
		
	}

	_getProfileAndUpdate(user){
		firebase.firestore().collection('profiles').doc(user.uid).get().then((profile) => {
			this.setState({
				user: user,
				profile: profile.data()
			}, () => {
				window.setTimeout(() => {
					this._initializeCloudMessaging();
				}, 5000);
				console.warn("profile updated");
			})
		});
	}

	_initializeCloudMessaging(){
		if(firebase.messaging.isSupported()){
			var messaging = this.props.firebase.messaging();
			//messaging.usePublicVapidKey("BAG9_XEUKGX7GeQ5QSgcnQCdz7AeoYe-Z_MBT9DFguGT6ex4PFI9Bq01KxbV5FA9i9Xw_TemVP7FHBJMd5P7V7M");
			this._requestPermission(messaging);
		}
	}

	_requestPermission(messaging){
		//var messaging = this.props.firebase.messaging();
		var that = this;
		messaging.requestPermission().then(function() {
			messaging.getToken().then(function(token) {
				//console.log(token)
				if(token) {
					that._saveTokenToUserProfile(token)
				} else {
					console.error('No Instance ID token available. Request permission to generate one.');
					that._requestPermission();
				}
			}).catch(function(err) {
				console.error('An error occurred while retrieving token. ', err);
			});
		}).catch(function(err) {
			console.error('Unable to get permission to notify.', err);
		});
	}

	_saveTokenToUserProfile(token){
		firebase.firestore().collection('profiles').doc(this.state.user.uid).set({ "fcm": token }, { merge: true }).then((_) => {
			console.info("Token saved")
		}).catch(function(err) {
			console.error('Unable to save user token to profile', err);
		});
	}

	logout(){
        if(window.confirm("Deseja fazer o logout do sistema?")){
            firebase.auth().signOut();
        }
    }
	
	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}
	
	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}
	
	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	renderRoutes(){
		return (<React.Fragment>
			<Route path="/" exact render={(props) => {
				//return <Home width={this.state.width} height={this.state.height} {...props}/>
				return <Redirect to="/projects"/>
			}} />
			<Route path="/projects" render={(props) => {
				return <Projects width={this.state.width} height={this.state.height} {...props} user={this.state.user} profile={this.state.profile}/>
			}} />
			<Route path="/items" render={(props) => {
				return <Items width={this.state.width} height={this.state.height} profile={this.state.profile} {...props}/>
			}} />
			<Route path="/finance" render={(props) => {
				return this.state.profile.role !== "staff" ? <Registries width={this.state.width} height={this.state.height} profile={this.state.profile} {...props}/> : null
			}} />
			<Route path="/users" render={(props) => {
				return <Users width={this.state.width} height={this.state.height} user={this.state.user}  profile={this.state.profile} {...props}/>
			}} />
			<Route path="/subsidiaries" render={(props) => {
				return this.state.profile.role === "superadmin" ? <Subsidiaries width={this.state.width} height={this.state.height} profile={this.state.profile} {...props}/> : null
			}} />
			{/*<ModalRoute 
				path='/projects/edit/:id' 
				className="sismodal"
				parentPath="/projects">
				<EditProject />
			</ModalRoute>
			<ModalRoute 
				path='/projects/:project/item/:item' 
				className="sismodal"
				parentPath="/projects/123">
				<EditItem/>
			</ModalRoute>
			<ModalRoute 
				path='/items/edit/:id' 
				className="sismodal"
				parentPath="/items">
				<EditItem/>
			</ModalRoute>
			<ModalRoute 
				path='/projects/:project/payment/:item' 
				className="sismodal"
				parentPath="/projects/123">
				<EditPayment/>
			</ModalRoute>
			<ModalRoute 
				path='/projects/:project/email/:id' 
				className="sismodal"
				parentPath="/projects/123">
				<Email/>
			</ModalRoute>
			<ModalRoute 
				path='/users/edit/:id' 
				className="sismodal">
				<EditUser/>
			</ModalRoute>
			<ModalRoute 
				path='/subsidiaries/edit/:id' 
				className="sismodal">
				<EditSubsidiary/>
			</ModalRoute>
			<ModalRoute 
				path='/profile/edit' 
				className="sismodal">
				<EditProfile 
					user={this.state.user}
					profile={this.state.profile ? this.state.profile : {}}/>
			</ModalRoute>
			<ModalContainer/>*/}
		</React.Fragment>)
	}

	render() {
		var offset = 0;
		if(this.props.location.pathname){
			if(this.props.location.pathname.lastIndexOf("/") > 0){
				offset = this.state.width * -1
			}
		}
		if(this.state.user == null){
			return (<div style={{width: this.state.width, height: this.state.height, justifyContent: "center", alignItems: "center", display: "flex"}}>
				<MDSpinner size="24" singleColor="#999999"/>
			</div>)
		} else {
			if(this.state.user === false){
				return (<div style={{width: this.state.width, height: this.state.height, justifyContent: "center", alignItems: "center"}}>
					<Login width={this.state.width} height={this.state.height}/>
				</div>)
			} else {
				if(this.state.profile.role != null && this.state.profile.subsidiary != null){
					if(this.state.width < 767){
						return (<React.Fragment>
							<div style={{...styles.roll, ...{ height: this.state.height }}}>
								<div style={{...{ width: this.state.width * 2, height: this.state.height, left: offset }, ...styles.mobile}}>
									<Route exact render={(props) => {
										return <SideBar 
											user={this.state.user} 
											profile={this.state.profile}
											width={this.state.width} 
											height={this.state.height} 
											update={() => this._getProfileAndUpdate(this.state.user)}
											{...props}/>
									}} />
									{this.renderRoutes()}
								</div>
							</div>
						</React.Fragment>);
					} else {
						return (<React.Fragment>
							<div style={{}}>
								<div style={{...{ width: this.state.width, height: this.state.height }, ...styles.desktop}}>
									<Route exact render={(props) => {
										return <SideBar 
											user={this.state.user} 
											profile={this.state.profile}
											width={this.state.width} 
											height={this.state.height} 
											update={() => this._getProfileAndUpdate(this.state.user)}
											{...props}/>
									}} />
									{this.renderRoutes()}
								</div>
							</div>
							
						</React.Fragment>);
					}
				} else {
					return <div style={{width: this.state.width, height: this.state.height, justifyContent: "center", alignItems: "center", display: "flex"}}>
						<div style={{maxWidth: "300px", textAlign: "center"}}>
							<i className="material-icons">warning</i>	
							<p>Sua conta precisa ser aprovada por um usuário administrador para que seu acesso seja liberado.</p>
							<p>
								<button style={styles.button} onClick={() => this.logout()} type="button">SAIR</button>
							</p>
						</div>
					</div>
				}
			}
		}
	}
}

const styles = {
	desktop: {
		display: "flex",
		flexDirection: "row"
	},
	mobile: {
		display: "flex",
		flexDirection: "column",
		position: "absolute",
		transition: "all 300ms ease",
		overflowX: "hidden",
		overflowY: "hidden",
	},
	roll: {
		position: "relative",
		overflowX: "hidden",
		overflowY: "hidden",
		paddingTop: "70px"
	},
	button: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		color: "#999999",
		padding: "5px 13px",
		borderRadius: "22px",
        fontSize: "12px",
        fontWeight: 300,
        border: "none",
    },
}

export default withRouter(App);

import React from "react";
import { Link } from "react-router-dom";
import { FirestoreDocument } from 'react-firestore';
import MDSpinner from 'react-md-spinner';
import * as firebase from 'firebase';

class Registry extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			
        };
    }
    delete(){
        if(window.confirm("Deseja deletar este registro?")){
            this.props.history.replace("/finance/")
            firebase.firestore().collection('registries').doc(this.props.match.params.id).delete().catch((e) => {
                console.error(e);
                alert("Ocorreu um erro ao deletar registro.")
            })
        }
    }
	render() {
		return (
			<div style={{...styles.body, ...{width: this.props.width - 371}, ...(this.props.width < 767 ? { width: this.props.width } : {})}}>
                <FirestoreDocument
                    path={"registries/" + this.props.match.params.id}
                    render={({isLoading, error, data}) => {
                        if(!isLoading) {
                            return (
                                <div style={styles.main}>
                                    <div style={styles.header}>
                                        <h3 style={styles.title}>{(data.amount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</h3>
                                        {data.flow === "income" ? <i className="material-icons" style={styles.income}>arrow_downward</i> : <i className="material-icons" style={styles.outcome}>arrow_upward</i> }
                                    </div>
                                    <div style={styles.canvas}>
                                        <div style={styles.block}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <p><strong>Tipo:</strong> {type(data.type)}</p>
                                                    {data.project ? <p><strong>Projeto:</strong> {data.project.name}</p> : null}
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div style={{...styles.actions, ...{width: this.props.width - 690}, ...(this.props.width < 767 ? { bottom: "70px", width: this.props.width} : {})}}>
                                            {this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <nav className="nav nav-pills nav-justified">
                                                <a onClick={() => this.delete()}>Deletar</a>
                                            </nav> : null}
                                        </div>
                                    </div>
                                </div>
                            );
                        } else {
                            return null
                        }
                    }}
                />
            </div>
		);
	}
}

const type = (string) => {
    //"cash", "check", "debit", "transfer", "credit", "billet"
    switch(string){
        case "cash":
        return "Dinheiro";
        break;

        case "check":
        return "Cheque";
        break;

        case "debit":
        return "Débito";
        break;

        case "transfer":
        return "Transferência";
        break;

        case "credit":
        return "Crédito";
        break;

        case "billet":
        return "Boleto";
        break;
    }
}

const styles = {
	body: {
        //borderLeft: "1px solid #EDEDED",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        maxWidth: "700px"
    },
    header: {
        padding: "15px",
        height: "80px",
        display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
    },
    title: {
        fontSize: "24px",
        fontWeight: 300,
        margin: "0px",
    },
    canvas: {
        padding: "15px",
    },
    main: {
        flex: 1,
        overflowX: "hidden",
        overflowY: "auto",
        paddingBottom: "85px"
    },
    block: {
        marginBottom: "30px"
    },
    row: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    column: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },
    list: {
        border: "1px solid #EDEDED",
        borderRadius: "7px",
        //backgroundColor: "#FFFFFF"
    },
    item: {
        padding: "15px",
        borderBottom: "1px solid #EDEDED",
        alignItems: "center",
        cursor: "pointer"
    },
    checkbox: {
        marginRight: "10px"
    },
    unit: {
        backgroundColor: "#EDEDED",
		color: "#444444",
		padding: "5px 10px",
		borderRadius: "16px",
        fontSize: "12px",
        marginRight: "10px",
        //marginLeft: "10px"
    },
    cost: {
        fontSize: "20px",
        fontWeight: "500",
        color: "#999999"
    },
    total: {
        fontSize: "28px",
        fontWeight: "500",
    },
    actions: {
        position: "fixed",
        bottom: "0px",
        left: "386px",
        padding: "15px 0px",
        backgroundColor: "#F7F7F7"
    },
    listactions: { 
        backgroundColor: "#FFF", 
        borderRadius: "0px 0px 5px 5px", 
        justifyContent: "center"
    },
    listactionsaction: {
        marginLeft: "15px"
    },
    outcome: {
		color: "#ff4949"
	},
	income: {
		color: "#32e100"
	},
}

export default Registry;

import React from "react";
import { Link, Route } from "react-router-dom";
import * as firebase from 'firebase';
import MDSpinner from 'react-md-spinner';

class EditTexts extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
            working: false,
            intro: "",
            footer: ""
            
        };
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount(){
        this.setState({
            intro: this.props.project.intro ? this.props.project.intro.replace(/<br ?\/?>/g, "\n") : "",
            footer: this.props.project.footer ? this.props.project.footer.replace(/<br ?\/?>/g, "\n") : ""
        });
    }

    

    save(){
        this.setState({
            working: true
        })
        var payload = {
            "intro": this.state.intro.replace(/\r?\n/g, '<br/>'),
            "footer": this.state.footer.replace(/\r?\n/g, '<br/>'),
        }
        firebase.firestore().collection('projects').doc(this.props.project.id).set(payload, { merge: true }).then(() => {
            firebase.firestore().collection('messages').add({ "project": this.props.project.id, "title": this.props.project.client.name, "message": this.props.profile.name + " editou os textos auxiliares da proposta", "created": new Date() })
            this.setState({
                working: false
            }, () => {
                this.close();
            })
        }).catch((e) => {
            console.error(e);
            alert("Ocorreu um erro ao salvar texto.")
            this.setState({
                working: false
            })
        })
    }

    close(){
        this.props.close();
    }

   
	render() {
		return (
			<div style={styles.body}>
                <div style={styles.header}>
                    <h3 style={styles.title}>Textos auxiliares</h3>
                </div>
                {<form style={styles.form}>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Introdução</label>
                        <textarea style={styles.input} placeholder="Texto introdutório da proposta" onChange={(e) => this.setState({ intro: e.target.value })} value={this.state.intro}></textarea>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Observações</label>
                        <textarea style={styles.input} placeholder="Observações da proposta" onChange={(e) => this.setState({ footer: e.target.value })} value={this.state.footer}></textarea>
                    </div>
                    <hr/>
                    
                    <div style={styles.actions}>
                        <button style={{...styles.button, ...styles.alt}} type="button" onClick={this.close}>CANCELAR</button>
                        {this.state.working ? <MDSpinner style={{ marginLeft: "15px" }} singleColor="#32e100" size="24"/> : <button style={styles.button} onClick={this.save} type="button">SALVAR</button>}
                    </div>
                </form>}
            </div>
		);
    }
}

const styles = {
	body: {
        
    },
    header: {
        height: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        backgroundColor: "#CCCCCC",
        color: "#999999",
    },
    title: {
        fontSize: "16px",
        textTransform: "uppercase",
        fontWeight: "300",
        margin: "0px",
        color: "#FFFFFF"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "50px"
    },
    field: {
        position: "relative",
    },
    label: {
        position: "absolute",
        top: "-7px",
        left: "10px",
        fontSize: "10px",
        padding: "0px 5px",
        backgroundColor: "#FFFFFF",
        color: "#CCCCCC",
        textTransform: "uppercase"
    },
    input: {
        border: "none",
        padding: "15px",
        paddingBottom: "20px",
        width: "100%"
    },
    select: {
        border: "none",
        margin: "15px 8px",
        paddingBottom: "20px",
        width: "calc(100% - 30px)",
        backgroundColor: "#FFFFFF"
    },
    actions: {
        padding: "15px",
        textAlign: "right"
    },
    supplier: {
        
    },
    button: {
		backgroundColor: "rgba(50, 225, 0, 1.0)",
		color: "#FFF",
		padding: "5px 13px",
		borderRadius: "22px",
        fontSize: "12px",
        fontWeight: 300,
        border: "none",
        marginLeft: "15px"
    },
    alt: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		color: "#999999",
    },
    delete: {
		backgroundColor: "#ff6868",
		color: "#FFFFFF",
    },
    white: {
		backgroundColor: "#FFFFFF",
		color: "#999999",
    },
    item: {
        margin: "0px",
        padding: "15px"
    }
}

export default EditTexts;

import React from "react";
import { Link, Route } from "react-router-dom";
import * as firebase from 'firebase';
import MDSpinner from 'react-md-spinner';
//import Countries from '../subsidiaries/Countries.js';
import { FirestoreCollection } from 'react-firestore';
import CurrencyInput from 'react-currency-masked-input'
import { InlineSuggest } from 'react-inline-suggest';

class AddItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
            working: true,
            department: "",
            category: "",
            brand: "",
            model: "",
            supplier: null,
            description: "",
            /*name: "",
            email: "",
            phone: "",
            address: "",
            postalcode: "",
            city: "",
            state: "",
            country: "Brasil",*/
            cost: 0,
            amount: 0,
            measure: "unit",
            code: "",
            suggestions: {
                "departments": [],
                "brands": [],
                "categories": []
            }
            //states: [{"name": "Acre"},{"name": "Alagoas"},{"name": "Amapá"},{"name": "Amazonas"},{"name": "Bahia"},{"name": "Ceará"},{"name": "Distrito Federal"},{"name": "Espírito Santo"},{"name": "Goiás"},{"name": "Maranhão"},{"name": "Mato Grosso"},{"name": "Mato Grosso do Sul"},{"name": "Minas Gerais"},{"name": "Pará"},{"name": "Paraíba"},{"name": "Paraná"},{"name": "Pernambuco"},{"name": "Piauí"},{"name": "Rio de Janeiro"},{"name": "Rio Grande do Norte"},{"name": "Rio Grande do Sul"},{"name": "Rondônia"},{"name": "Roraima"},{"name": "Santa Catarina"},{"name": "São Paulo"},{"name": "Sergipe"},{"name": "Tocantins"}],
        };
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount(){
        if(this.props.id !== "new"){
            firebase.firestore().collection('items').doc(this.props.id).get().then((item) => {
                this.setState({
                    working: false,
                    department: item.data().department,
                    category: item.data().category,
                    brand: item.data().brand,
                    model: item.data().model,
                    description: item.data().description ? item.data().description.replace(/<br ?\/?>/g, "\n") : "",
                    supplier: item.data().supplier,
                    /*name: item.data().supplier ? item.data().supplier.name : "",
                    email: item.data().supplier ? item.data().supplier.email : "",
                    phone: item.data().supplier ? item.data().supplier.phone : "",
                    address: item.data().supplier ? item.data().supplier.location ? item.data().supplier.location.address : "" : "",
                    postalcode: item.data().supplier ? item.data().supplier.location ? item.data().supplier.location.postalcode : "" : "",
                    city: item.data().supplier ? item.data().supplier.location ? item.data().supplier.location.city : "" : "",
                    state: item.data().supplier ? item.data().supplier.location ? item.data().supplier.location.state : "" : "",
                    country: item.data().supplier ? item.data().supplier.location?  item.data().supplier.location.country : "" : "",*/
                    cost: item.data().cost,
                    amount: item.data().amount,
                    measure: item.data().measure,
                    code: item.data().code.value,
                })
            });
        } else {
            this.setState({
                working: false
            })
        }
        firebase.firestore().collection('items').get().then((documents) => {
            this.setState({
                suggestions: {
                    "departments": Object.keys(groupBy(documents.docs, "department")),
                    "brands": Object.keys(groupBy(documents.docs, "brand")),
                    "categories": Object.keys(groupBy(documents.docs, "category"))
                }
            })
        })
    }

    save(){
        if(this.state.category !== "" && this.state.brand !== "" && this.state.model !== "" && this.state.amount !== "" && this.state.measure !== "" && this.state.department !== ""){
            this.setState({
                working: true
            })
            var payload = {
                "department": this.state.department,
                "category": this.state.category,
                "brand": this.state.brand,
                "model": this.state.model,
                "description": this.state.description.replace(/\r?\n/g, '<br/>'),
                "cost": parseInt(this.state.cost.toString().replace(".", "")),
                "amount": parseInt(this.state.amount.toString().replace(".", "")),
                "measure": this.state.measure,
                "code": {
                    "type": "ean13",
                    "value": this.state.code
                },
                "supplier": this.state.supplier
            }
            /*if(this.props.supplier != null || (this.props.name != "" && this.props.email != "")){
                payload.supplier = {
                    "name": this.state.name,
                    "email": this.state.email,
                    "phone": parseInt(this.state.phone),
                    "location": {
                        "address": this.state.address,
                        "postalcode": parseInt(this.state.postalcode),
                        "city": this.state.city,
                        "state": this.state.state,
                        "country": this.state.country
                    },
                }
            }*/
            if(this.props.id !== "new"){
                firebase.firestore().collection('items').doc(this.props.id).set(payload, { merge: true }).then(() => {
                    this.setState({
                        working: false
                    }, () => {
                        this.close();
                    })
                }).catch((e) => {
                    console.error(e);
                    alert("Ocorreu um erro ao salvar novo item.")
                    this.setState({
                        working: false
                    })
                })
            } else {
                firebase.firestore().collection('items').add(payload).then((returned) => {
                    this.setState({
                        working: false
                    }, () => {
                        this.close(returned.id);
                    })
                }).catch((e) => {
                    console.error(e);
                    alert("Ocorreu um erro ao salvar novo item.")
                    this.setState({
                        working: false
                    })
                })
            }
            if(this.state.supplier !== null){
                // salvar supplier
            }
        } else {
            alert("Preencha os campos obrigatórios (*) para continuar.")
        }
    }

    close(goto = null){
        this.props.close(typeof goto === "String" ? goto : null);
    }

	render() {
        //console.log(this.state)
		return (
			<div style={styles.body}>
                <div style={styles.header}>
                    <h3 style={styles.title}>Item</h3>
                </div>
                <form style={styles.form}>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Departamento *</label>
                        {/*<input type="text" placeholder="Departamento" style={styles.input} value={this.state.department} onChange={(e) => this.setState({ department: e.target.value })}/>*/}
                        <InlineSuggest 
                            haystack={this.state.suggestions.departments}
                            value={this.state.department}
                            onChange={(e) => this.setState({ department: e.target.value })}
                            onMatch={(v) => this.setState({ department: v })}
                            switchBetweenSuggestions={true}
                            ignoreCase={true}
                        />     
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Categoria *</label>
                        {/*<input type="text" placeholder="Receiver" style={styles.input} value={this.state.category} onChange={(e) => this.setState({ category: e.target.value })}/>*/}
                        <InlineSuggest 
                            haystack={this.state.suggestions.categories}
                            value={this.state.category}
                            onChange={(e) => this.setState({ category: e.target.value })}
                            onMatch={(v) => this.setState({ category: v })}
                            switchBetweenSuggestions={true}
                            ignoreCase={true}
                        />   
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Marca *</label>
                        {/*<input type="text" placeholder="Sony" style={styles.input} value={this.state.brand} onChange={(e) => this.setState({ brand: e.target.value })}/>*/}
                        <InlineSuggest 
                            haystack={this.state.suggestions.brands}
                            value={this.state.brand}
                            onChange={(e) => this.setState({ brand: e.target.value })}
                            onMatch={(v) => this.setState({ brand: v })}
                            switchBetweenSuggestions={true}
                            ignoreCase={true}
                        /> 
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Modelo *</label>
                        <input type="text" placeholder="AXU-1221" style={styles.input} value={this.state.model} onChange={(e) => this.setState({ model: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Descrição</label>
                        <textarea style={styles.input} value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })}></textarea>
                    </div>
                    <hr/>
                    {/*<div style={styles.field}>
                        <label style={styles.label}>Nome</label>
                        <input type="text" placeholder="Nome" style={styles.input} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>E-mail</label>
                        <input type="email" placeholder="nome@email.com" style={styles.input} value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Telefone</label>
                        <input type="number" placeholder="99 9999 9999" style={styles.input} value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Endereço</label>
                        <input type="text" placeholder="Rua 10" style={styles.input} value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>CEP</label>
                        <input type="number" placeholder="999999 999" style={styles.input} value={this.state.postalcode} onChange={(e) => this.setState({ postalcode: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Cidade</label>
                        <input type="text" placeholder="Cidade" style={styles.input} value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Estado</label>
                        {this.state.country == "Brasil" ? <select style={styles.select} value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })}>
                            {
                                this.state.states.map((state, i) => {
                                    return <option value={state.name} key={i}>{state.name}</option>
                                })
                            }
                        </select> : <input type="text" placeholder="Nome do estado" style={styles.input} value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })}/> }
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>País</label>
                        <select style={styles.select} value={this.state.country} onChange={(e) => this.setState({ country: e.target.value })}>
                            {
                                Countries.map((country, i) => {
                                    return <option value={country.nome_pais} key={i}>{country.nome_pais}</option>
                                })
                            }
                        </select>
                    </div>
                    <hr/>*/}
                    <div style={styles.field}>
                        <label style={styles.label}>Medida *</label>
                        <select style={styles.select}  value={this.state.measure} onChange={(e) => this.setState({ measure: e.target.value })}>
                            <option value="unit">Unidade</option>
                            <option value="pair">Par</option>
                            <option value="meter">Metro</option>
                            <option value="hour">Hora</option>
                        </select>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Código</label>
                        <input type="text" placeholder="789299493" style={styles.input} value={this.state.code} onChange={(e) => this.setState({ code: e.target.value })}/>
                    </div>
                    <hr/>
                    {!this.state.working ? <div>
                        <div style={styles.field}>
                            <label style={styles.label}>Custo</label>
                            <CurrencyInput placeholder="1500.00" style={styles.input} defaultValue={(this.state.cost / 100).toFixed(2).toString()} onChange={(e) => this.setState({ cost: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Valor *</label>
                            <CurrencyInput placeholder="1500.00" style={styles.input} defaultValue={(this.state.amount / 100).toFixed(2).toString()} onChange={(e) => this.setState({ amount: e.target.value })}/>
                        </div>
                    </div> : null}
                    <div style={styles.field}>
                        <label style={styles.label}>Fornecedor</label>
                        <FirestoreCollection
                            path="profiles"
                            filter={["role", "==", "supplier"]}
                            render={({isLoading, error, data}) => {
                                return <select style={styles.select} value={this.state.supplier ? this.state.supplier : ""} onChange={(e) => this.setState({ supplier: e.target.value })}>
                                    <option value={""}>{"Selecione um fornecedor"}</option>
                                    {
                                        data.map((user, i) => {
                                            return <option value={user.id} key={i} selected={this.state.supplier == user.id ? true : false}>{user.name}</option>
                                        })
                                    }
                                </select>
                            }}
                        />
                    </div>
                    <hr/>
                    <div style={styles.actions}>
                        <button style={{...styles.button, ...styles.alt}} type="button" onClick={this.close}>CANCELAR</button>
                        {this.state.working ? <MDSpinner style={{ marginLeft: "15px" }} singleColor="#32e100" size="24"/> : <button style={styles.button} onClick={this.save} type="button">SALVAR</button>}
                    </div>
                </form>
            </div>
		);
	}
}

const styles = {
	body: {
        
    },
    header: {
        height: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        backgroundColor: "#CCCCCC",
        color: "#999999",
    },
    title: {
        fontSize: "16px",
        textTransform: "uppercase",
        fontWeight: "300",
        margin: "0px",
        color: "#FFFFFF"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "50px"
    },
    field: {
        position: "relative",
    },
    label: {
        position: "absolute",
        top: "-7px",
        left: "10px",
        fontSize: "10px",
        padding: "0px 5px",
        backgroundColor: "#FFFFFF",
        color: "#CCCCCC",
        textTransform: "uppercase"
    },
    input: {
        border: "none",
        padding: "15px",
        paddingBottom: "20px",
        width: "100%"
    },
    select: {
        border: "none",
        margin: "15px 8px",
        paddingBottom: "20px",
        width: "calc(100% - 18px)",
        backgroundColor: "#FFFFFF"
    },
    actions: {
        padding: "15px",
        textAlign: "right"
    },
    button: {
		backgroundColor: "rgba(50, 225, 0, 1.0)",
		color: "#FFF",
		padding: "5px 13px",
		borderRadius: "22px",
        fontSize: "12px",
        fontWeight: 300,
        border: "none",
        marginLeft: "15px"
    },
    alt: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		color: "#999999",
	},
}

const groupBy = function(xs, key) {
	return xs.reduce(function(rv, x) {
		(rv[x.data()[key]] = rv[x.data()[key]] || []).push(x);
		return rv;
	}, {});
};

export default AddItem;

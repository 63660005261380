import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router}  from 'react-router-dom';
import App from './routes/App.js';
import firebase from '@firebase/app';
import { FirestoreProvider } from 'react-firestore';

let config = {
    apiKey: "AIzaSyCPOzIClI8Pt5ltZe4OZ0tMbP76wc1CyRY",
    authDomain: "hommax-44250.firebaseapp.com",
    databaseURL: "https://hommax-44250.firebaseio.com",
    projectId: "hommax-44250",
    storageBucket: "hommax-44250.appspot.com",
    messagingSenderId: "424931898014",
}
firebase.initializeApp(config);
const firestore = firebase.firestore();
const settings = { timestampsInSnapshots: true };
firestore.settings(settings);




ReactDOM.render(<Router>
    <FirestoreProvider firebase={firebase} useTimestampsInSnapshots>
        <App firebase={firebase}/>
    </FirestoreProvider>
</Router>, document.getElementById('root'));


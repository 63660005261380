import React from "react";
import logo from '../assets/logo.png';
import MDSpinner from 'react-md-spinner';
import MaskedInput from 'react-maskedinput'

import * as firebase from 'firebase';

class Login extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            email: "",
            password: "",
            name: "",
            phone: "",
            working: false,
            mode: "signin"
        }
    }

    signin(){
        if(this.state.email != "" && this.state.password != ""){
            this.setState({
                working: true
            }, () => {
                firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).catch((e) => {
                    alert("Ocorreu um erro ao efetuar login (" + e.message + ")");
                    this.setState({
                        working: false,
                        password: ""
                    });
                })
            })
        } else {
            alert("Preencha todos os campos para continuar");
        }
    }

    signup(){
        if(this.state.email !== "" && this.state.password !== "" && this.state.name !== "" && this.state.phone !== ""){
            this.setState({
                working: true,
            }, () => {
                firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then((data) => {
                    let payload = {
                        "name": this.state.name,
                        "email": this.state.email,
                        "phone": this.state.phone,
                        "role": null
                    }
                    firebase.firestore().collection('profiles').doc(data.user.uid).set(payload).then(() => {
                        this.setState({
                            working: false,
                            password: ""
                        });
                    }).catch((e) => {
                        alert("Ocorreu um erro ao criar perfil da conta (" + e.message + ")");
                        this.setState({
                            working: false,
                            password: ""
                        });
                    })
                }).catch((e) => {
                    alert("Ocorreu um erro ao criar conta (" + e.message + ")");
                    this.setState({
                        working: false,
                        password: ""
                    });
                })
            });
        } else {
            alert("Preencha todos os campos para continuar");
        }
    }

    recover(){
        if(window.confirm("Deseja recuperar sua senha de acesso?")){
            firebase.auth().sendPasswordResetEmail(this.state.email).then(function() {
                alert("Em instantes você receberá um e-mail com instruções para resetar a senha da sua conta");
            }).catch(function(e) {
                alert("Ocorreu um erro ao recuperar conta (" + e.message + ")");
            });
        }
    }

    validate(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    render(){
        return (<div style={{ width: this.props.width, height: this.props.height, display: 'flex', alignItems: "center", justifyContent: "center" }}>
            {this.state.working ? <MDSpinner size="24" singleColor="#999999"/> : <div style={styles.body}>
                <img src={logo} width="150" style={styles.logo}/>
                
                    {this.state.mode === "signin" ? <React.Fragment>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>E-mail</label>
                            <input type="email" placeholder="seunome@email.com" style={styles.input} value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Senha</label>
                            <input type="password" placeholder="Senha" style={styles.input} value={this.state.password} onChange={(e) => e.key === 'Enter' ? this.signin.bind(this) : this.setState({ password: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.actions}>
                            {this.validate(this.state.email ) ? <button style={{...styles.button, ...styles.alt, ...{float: 'left', marginLeft: '0px'}}} onClick={this.recover.bind(this)}>?</button> : null}
                            <button style={{...styles.button, ...styles.alt}} onClick={() => this.setState({ mode: "signup" })}>REGISTRAR</button>
                            <button style={styles.button} onClick={this.signin.bind(this)}>ENTRAR</button>
                        </div>
                    </React.Fragment> : <React.Fragment>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>E-mail</label>
                            <input type="email" placeholder="seunome@email.com" style={styles.input} value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Nome</label>
                            <input type="email" placeholder="Nome completo" style={styles.input} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Telefone</label>
                            <MaskedInput type="text" placeholder="(99)999999999" mask="(11)11111-1111" size="13" style={styles.input} value={this.state.phone.toString()} onChange={(e) => this.setState({ phone: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Senha</label>
                            <input type="password" placeholder="Senha" style={styles.input} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.actions}>
                            <button style={{...styles.button, ...styles.alt}} onClick={() => this.setState({ mode: "signin" })}>ENTRAR</button>
                            <button style={styles.button} onClick={this.signup.bind(this)}>REGISTRAR</button>
                        </div>
                    </React.Fragment>}
                
            </div>}
        </div>)
    }
}

const styles = {
	body: {
		display: "flex",
        flexDirection: "column",
        width: "300px",
        border: "1px solid #EDEDED",
        borderRadius: "25px",
        backgroundColor: "#FFF"
    },
    logo: {
        margin: "30px auto"
    },
    subtitle: {
        fontSize: "12px",
        fontWeight: 300,
        color: "#999999",
        textAlign: "center",
        marginBottom: "15px"
    },
    field: {
        position: "relative",
    },
    label: {
        position: "absolute",
        top: "-7px",
        left: "10px",
        fontSize: "10px",
        padding: "0px 5px",
        backgroundColor: "#FFFFFF",
        color: "#CCCCCC",
        textTransform: "uppercase"
    },
    input: {
        border: "none",
        padding: "15px",
        paddingBottom: "20px",
        width: "100%",
        backgroundColor: "transparent"
    },
    actions: {
        padding: "15px",
        textAlign: "right"
    },
    button: {
		backgroundColor: "rgba(50, 225, 0, 1.0)",
		color: "#FFF",
		padding: "5px 13px",
		borderRadius: "22px",
        fontSize: "12px",
        fontWeight: 300,
        border: "none",
        marginLeft: "15px"
    },
    alt: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		color: "#999999",
	},
}

export default Login
import React from "react";
import { Link, Route } from "react-router-dom";
import * as firebase from 'firebase';
import MDSpinner from 'react-md-spinner';
import CurrencyInput from 'react-currency-masked-input'

class AddRegistry extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
            working: false,
            type: "cash",
            flow: "income",
            amount: 0
        };
        this.save = this.save.bind(this)
    }
    save(){
        this.setState({
            working: true
        }, () => {
            firebase.firestore().collection('registries').add({
                "type": this.state.type,
                "flow": this.state.flow,
                "amount": parseInt(this.state.amount.toString().replace(".", "")),
                "subsidiary": this.props.profile.subsidiary.id,
                "created": new Date()
            }).then(() => {
                this.setState({
                    working: false
                }, () => {
                    this.props.close();
                })
            }).catch((e) => {
                console.error(e);
                alert("Ocorreu um erro ao salvar novo registro.")
                this.setState({
                    working: false
                })
            })
        })
    }
	render() {
		return (
			<div style={styles.body}>
                <div style={styles.header}>
                    <h3 style={styles.title}>E-mail</h3>
                </div>
                <form style={styles.form}>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Tipo</label>
                        <select style={styles.select} value={this.state.type} onChange={(e) => this.setState({ type: e.target.value })}>
                            <option value={"cash"}>Dinheiro</option>   
                            <option value={"check"}>Cheque</option>   
                            <option value={"debit"}>Debito</option>   
                            <option value={"credit"}>Crédito</option>   
                            <option value={"transfer"}>Transferência</option>   
                            <option value={"billet"}>Boleto</option>   
                        </select>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Fluxo</label>
                        <select style={styles.select} value={this.state.flow} onChange={(e) => this.setState({ flow: e.target.value })}>
                            <option value={"income"}>Entrada</option>   
                            <option value={"outcome"}>Saída</option>   
                        </select>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Valor</label>
                        <CurrencyInput placeholder="1500.00" style={styles.input} defaultValue={(this.state.amount / 100).toFixed(2).toString()} onChange={(e) => this.setState({ amount: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.actions}>
                        {this.state.working ? <MDSpinner style={{ marginLeft: "15px" }} singleColor="#32e100" size="24"/> : <button style={styles.button} type="button" onClick={this.save}>SALVAR</button>}
                    </div>
                </form>
            </div>
		);
	}
}

const styles = {
	body: {
        
    },
    header: {
        height: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        backgroundColor: "#CCCCCC",
        color: "#999999",
    },
    title: {
        fontSize: "16px",
        textTransform: "uppercase",
        fontWeight: "300",
        margin: "0px",
        color: "#FFFFFF"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "50px"
    },
    field: {
        position: "relative",
    },
    label: {
        position: "absolute",
        top: "-7px",
        left: "10px",
        fontSize: "10px",
        padding: "0px 5px",
        backgroundColor: "#FFFFFF",
        color: "#CCCCCC",
        textTransform: "uppercase"
    },
    input: {
        border: "none",
        padding: "15px",
        paddingBottom: "20px",
        width: "100%"
    },
    select: {
        border: "none",
        margin: "15px 8px",
        paddingBottom: "20px",
        width: "calc(100% - 30px)",
        backgroundColor: "#FFFFFF"
    },
    checkbox: {
        padding: "18px",
        margin: "0px"
    },
    actions: {
        padding: "15px",
        textAlign: "right"
    },
    button: {
		backgroundColor: "rgba(50, 225, 0, 1.0)",
		color: "#FFF",
		padding: "5px 13px",
		borderRadius: "22px",
        fontSize: "12px",
        fontWeight: 300,
        border: "none",
        marginLeft: "15px"
    },
    alt: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		color: "#999999",
	},
}

export default AddRegistry;

import React from "react";
import { Link, withRouter } from "react-router-dom";
import * as firebase from 'firebase';
import MDSpinner from 'react-md-spinner';
import Moment from 'moment';
import MaskedInput from 'react-maskedinput'

class EditProfile extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
            name: this.props.profile.name,
            email: this.props.user.email,
            photo: this.props.profile.photo,
            phone: this.props.profile.phone,
            working: false
        };
        this.password = this.password.bind(this);
        this.close = this.close.bind(this);
        this.logout = this.logout.bind(this);
    }

    save(){
        this.setState({
            working: true
        }, () => {
            this.props.user.updateProfile({
                displayName: this.state.name,
                photoURL: this.state.photo
            }).then(() => {
                var payload = {
                    "name": this.state.name,
                    "email": this.state.email,
                    "phone": parseInt(this.state.phone),
                    "photo": this.state.photo ? this.state.photo : null,
                };
                firebase.firestore().collection('profiles').doc(this.props.user.uid).set(payload, { merge: true }).then(() => {
                    this.setState({
                        working: false
                    }, () => {
                        this.props.update();
                        this.close();
                    })
                }).catch((e) => {
                    console.error(e);
                    alert("Ocorreu um erro ao salvar alterações do perfil.")
                    this.setState({
                        working: false
                    })
                })
            }, (e) => {
                console.error(e);
                alert("Ocorreu um erro ao salvar alterações de usuário.")
                this.setState({
                    working: false
                })
            });
        });
        
    }

    upload(e){
        var that = this;
        const storage = firebase.storage();
        var file = e.target.files[0];


        //var file = e.target.files[0];
        var reader = new FileReader();
        reader.onload = (e) => {
            var img = document.createElement("img");
            img.onload = () => {
                var canvas = document.createElement('canvas');
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);

                var MAX_WIDTH = 120;
                var MAX_HEIGHT = 120;
                var width = img.width;
                var height = img.height;

                if (width > height) {
                    if (width > MAX_WIDTH) {
                    height *= MAX_WIDTH / width;
                    width = MAX_WIDTH;
                    }
                } else {
                    if (height > MAX_HEIGHT) {
                    width *= MAX_HEIGHT / height;
                    height = MAX_HEIGHT;
                    }
                }
                canvas.width = width;
                canvas.height = height;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, width, height);
                var dataurl = canvas.toDataURL("image/png");
                var string = dataurl.substring((dataurl.indexOf("base64,") + 7), dataurl.length)
                //console.log(dataurl)
                //console.log(string)
                //this.setState({previewSrc: dataurl});
                const task = storage.ref().child("images/avatar/" + this.props.user.uid + "@" + new Date().getTime()).putString(string, 'base64'); 
                this.setState({
                    working: true,
                }, () => {
                    task.then(() => {
                        task.snapshot.ref.getDownloadURL().then(function(url) {
                            that.setState({
                                working: false,
                                photo: url,
                            }, () => {
                                that.save();
                            });
                        }).catch((e) => {
                            console.error(e);
                            alert("Ocorreu um erro ao obter endereço da imagem enviada.")
                            this.setState({
                                working: false
                            })
                        });
                    }).catch((e) => {
                        console.error(e);
                        alert("Ocorreu um erro ao enviar imagem.")
                        this.setState({
                            working: false
                        })
                    });
                });
            }
            img.src = e.target.result;
        }
        reader.readAsDataURL(file);



        /*c*/
    }

    close(){
        this.props.close();
    }

    password(){
        this.props.password();
    }

    logout(){
        if(window.confirm("Deseja fazer o logout do sistema?")){
            firebase.auth().signOut();
        }
    }

	render() {
        //console.log(this);
		return (
			<div style={styles.body}>
                <div style={styles.header}>
                    <h3 style={styles.title}>Meus dados</h3>
                    <div>
                        <button style={{...styles.button, ...styles.white}} type="button" onClick={this.password}>SENHA</button>
                        <button style={{...styles.button, ...styles.white}} type="button" onClick={this.logout}>SAIR</button>
                    </div>
                </div>
                <form style={styles.form}>
                    <div style={styles.row}>
                        <div>
                            <div style={{...styles.avatar, ...{ backgroundImage: 'url(' + this.state.photo + ')'}}}></div>
                        </div>
                        <div>
                            <input type="file" accept="image/*" onChange={this.upload.bind(this)}/>
                        </div>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Nome</label>
                        <input type="text" placeholder="Fulano de Tal" style={styles.input} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>E-mail</label>
                        <input type="email" placeholder="nome@email.com" style={styles.input} value={this.state.email} disabled/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Telefone</label>
                        <MaskedInput type="text" mask="(11)11111-1111" size="13" style={styles.input} value={this.state.phone.toString()} onChange={(e) => this.setState({ phone: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.actions}>
                        <button style={{...styles.button, ...styles.alt}} type="button" onClick={this.close}>CANCELAR</button>
                        {this.state.working ? <MDSpinner style={{ marginLeft: "15px" }} singleColor="#32e100" size="24"/> : <button style={styles.button} onClick={this.save.bind(this)} type="button">SALVAR</button>}
                    </div>
                </form>
            </div>
		);
	}
}

const styles = {
	body: {
        
    },
    header: {
        height: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        backgroundColor: "#CCCCCC",
        color: "#999999",
    },
    title: {
        fontSize: "16px",
        textTransform: "uppercase",
        fontWeight: "300",
        margin: "0px",
        color: "#FFFFFF"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "15px"
    },
    field: {
        position: "relative",
    },
    label: {
        position: "absolute",
        top: "-7px",
        left: "10px",
        fontSize: "10px",
        padding: "0px 5px",
        backgroundColor: "#FFFFFF",
        color: "#CCCCCC",
        textTransform: "uppercase"
    },
    input: {
        border: "none",
        padding: "15px",
        paddingBottom: "20px",
        width: "100%"
    },
    select: {
        border: "none",
        margin: "15px 8px",
        paddingBottom: "20px",
        width: "calc(100% - 30px)",
        backgroundColor: "#FFFFFF"
    },
    actions: {
        padding: "15px",
        textAlign: "right"
    },
    button: {
		backgroundColor: "rgba(50, 225, 0, 1.0)",
		color: "#FFF",
		padding: "5px 13px",
		borderRadius: "22px",
        fontSize: "12px",
        fontWeight: 300,
        border: "none",
        marginLeft: "15px"
    },
    alt: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		color: "#999999",
    },
    white: {
		backgroundColor: "#FFFFFF",
		color: "#999999",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        padding: "15px",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    avatar: {
		width: "40px",
		height: "40px",
		borderRadius: "20px",
        backgroundColor: "#DDDDDD",
        backgroundSize: "cover",
        backgroundPosition: "center",
		marginRight: "15px"
	}
}

export default withRouter(EditProfile);

import React from "react";
import { Link } from "react-router-dom";
import { FirestoreDocument } from 'react-firestore';
import * as firebase from 'firebase';
import Moment from 'moment';
import MDSpinner from 'react-md-spinner';
import { userInfo } from "os";
import Modal from 'react-modal';
import EditUser from "./EditUser";


class User extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			modal: null
		};
    }
    
    renderBadge(role){
        switch(role){
            case "superadmin":
            return <span style={styles.badge}>SUPER ADMIN</span>

            case "admin":
            return <span style={styles.badge}>ADMIN</span>

            case "staff":
            return <span style={styles.badge}>STAFF</span>

            case "supplier":
            return <span style={styles.badge}>FORNECEDOR</span>

            default:
            return <span style={styles.badge}>{role.toUpperCase()}</span>
        }
    }

    delete(){
        if(window.confirm("Deseja realmente deletar este fornecedor?")){
            firebase.firestore().collection('profiles').doc(this.props.match.params.id).delete().then(() => {
                this.props.history.push("/users/")
            }).catch((e) => {
                console.error(e);
                alert("Ocorreu um erro ao deletar fornecedor.")
            })
        }
    }

	render() {
        return (<div style={{...styles.body, ...{width: this.props.width - 371}, ...(this.props.width < 767 ? { width: this.props.width } : {})}}>
            <FirestoreDocument
                path={"profiles/" + this.props.match.params.id}
                render={({isLoading, error, data}) => {
                    if(!isLoading) {
                        return (
                            <div style={styles.main}>
                                <div style={styles.header}>
                                    <h3 style={styles.title}>{data.name}</h3>
                                    {data.role ? this.renderBadge(data.role) : null}
                                </div>
                                <div style={styles.canvas}>
                                    <div style={styles.block}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <p><strong>E-mail:</strong> {data.email}</p>
                                                <p><strong>Telefone:</strong> {data.phone}</p>
                                            </div>
                                            {data.role !== "supplier" ? <div className="col-md-6">
                                                <p><strong>Filial:</strong> {data.subsidiary ? data.subsidiary.name : "Sem filial"}</p>
                                            </div> : <div className="col-md-6">
                                                <p><strong>Endereço:</strong> {data.location.address}</p>
                                                <p>{data.location.postalcode}, {data.location.city}, {data.location.state}, {data.location.country}</p> 
                                            </div>}
                                        </div>
                                    </div>
                                    
                                    <div style={{...styles.actions, ...{width: this.props.width - 690}, ...(this.props.width < 767 ? { bottom: "70px", width: this.props.width} : {})}}>
                                        <nav className="nav nav-pills nav-justified">
                                            {data.role != "superadmin" ? this.props.profile.role !== "staff" ? <a onClick={() => this.setState({ modal: data.id })} className="nav-link">Editar</a> : null : null}
                                            {data.role === "supplier" && this.props.profile.role === "superadmin" ? <a onClick={() => this.delete()} className="nav-link" >Deletar</a> : null}
                                        </nav>
                                        
                                    </div>
                                </div>
                            </div>
                        );
                    } else {
                        return null
                    }
                }}
            />
            <Modal isOpen={this.state.modal ? true : false} style={styles.modal}  onRequestClose={(goto) => this.setState({ modal: null })}>
                <EditUser id={this.state.modal} close={(goto) => this.setState({ modal: null })} profile={this.props.profile}/>
            </Modal>
        </div>)
	}
}

const styles = {
	body: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        maxWidth: "700px"
    },
    header: {
        padding: "15px",
        height: "80px",
        display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
    },
    title: {
        fontSize: "24px",
        fontWeight: 300,
        margin: "0px",
    },
    badge: {
		backgroundColor: "#32e100",
		color: "#FFFFFF",
		padding: "5px 10px",
		borderRadius: "16px",
        fontSize: "12px",
	},
    canvas: {
        padding: "15px",
    },
    main: {
        flex: 1,
        overflowX: "hidden",
        overflowY: "auto",
        paddingBottom: "85px"
    },
    block: {
        marginBottom: "30px"
    },
    row: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    column: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },
    list: {
        border: "1px solid #EDEDED",
        borderRadius: "7px",
        //backgroundColor: "#FFFFFF"
    },
    item: {
        padding: "15px",
        borderBottom: "1px solid #EDEDED",
        alignItems: "center",
        cursor: "pointer"
    },
    checkbox: {
        marginRight: "10px"
    },
    unit: {
        backgroundColor: "#EDEDED",
		color: "#444444",
		padding: "5px 10px",
		borderRadius: "16px",
        fontSize: "12px",
        marginRight: "10px",
        //marginLeft: "10px"
    },
    cost: {
        fontSize: "20px",
        fontWeight: "500",
        color: "#999999"
    },
    total: {
        fontSize: "28px",
        fontWeight: "500",
    },
    actions: {
        position: "absolute",
        bottom: "0px",
        left: "375px",
        padding: "15px 15px",
        backgroundColor: "#F7F7F7",
    },
    listactions: { 
        backgroundColor: "#FFF", 
        borderRadius: "0px 0px 5px 5px", 
        justifyContent: "center"
    },
    listactionsaction: {
        marginLeft: "15px"
    },
    loading: {
        display: "flex",
        flex: 1,
		alignItems: "center",
		justifyContent: "center"
    },
    modal: {
		overlay: {
            backgroundColor: "rgba(0,0,0,.5)",
            overflowY: "auto"
		},
		content: {
            margin: "0px auto",
            padding: "0px",
            maxWidth: "400px",
            minWidth: "300px",
		}
	}
}

export default User;

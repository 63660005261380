import React from "react";
import { Link, Route } from "react-router-dom";


class Home extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			
		};
	}
	render() {
		return (
			<div style={{...styles.body, ...(this.props.width < 767 ? styles.mobile : styles.desktop), ...{ width: this.props.width - (this.props.width < 767 ? 0 : 71), height: this.props.height - (this.props.width < 767 ? 71 : 0) }}}>
				<Link to="/projects/edit/new" style={styles.item}>
                    <i style={styles.icon} className="material-icons">add</i>
                    <h3 style={styles.title}>PROJETO</h3>
                </Link>
            </div>
		);
	}
}

const styles = {
	body: {
		display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflowX: "hidden",
        overflowY: "auto",
    },
    mobile: {
        flexDirection: "column",
    },
    desktop: {
        flexDirection: "row",
    },
    item: {
        width: "150px",
        height: "150px",
        margin: "15px",
        backgroundColor: "#FFFFFF",
        border: "1px solid rgba(0,0,0,.05)",
        borderRadius: "15px",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column"
    },
    title: {
        fontSize: "14px",
        fontWeight: 300
    },
    icon: {
        fontSize: "50px",
        color: "#CCC"
    }
}

export default Home;

import React from "react";
import { Link, Route } from "react-router-dom";
import { FirestoreCollection } from 'react-firestore';
import MDSpinner from 'react-md-spinner';
import Project from './Project.js';
import Modal from 'react-modal';
import EditProject from "./EditProject";

class Projects extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			modal: null,
			search: "",
			filter: null
		};
	}
	renderItem(isLoading, error, data){
		if(isLoading){
			return <div style={styles.loading}><MDSpinner style={{ marginLeft: "15px" }} singleColor="#999999" size="24"/></div>
		} else {
			return ((this.state.search.length > 0 ? data.filter((i) => i.client.name.toLowerCase().search(this.state.search.toLowerCase()) >= 0) : data).map((item, i) => {
				return item.status !== "deleted" || this.state.filter === "deleted" ? <Link to={"/projects/" + item.id} style={{...styles.item, ...(this.props.location.pathname === ("/projects/" + item.id) ? styles.active : {})}} key={i}>
					<div>
					{item.reference ? <i className="material-icons" style={{"position": "relative", "fontSize": "11px", "top": "0px"}}>file_copy</i> : null} {item.client.name}
						{this.props.profile.role === "superadmin" ? <p style={styles.subtitle}>{item.subsidiary ? item.subsidiary.name : "- -"}</p> : null}
					</div>
					<div style={{ alignItems: "center", justifyContent: "center", display: "flex" }}>
						<span style={{...styles.badge, ...{ backgroundColor: this.status(item.status).color }}}>{this.status(item.status).label.toUpperCase()}</span>
					</div>
				</Link> : null;
			}));
		}
	}
	render() {
		return (
			<div style={{...styles.body, ...(this.props.width < 767 ? { width: "100%" } : { width: this.props.width - 71 })}}>
				<div style={{...styles.list, ...(this.props.width < 767 ? { width: this.props.width, height: this.props.height - 70 } : { width: "300px", borderRight: "1px solid #EDEDED", height: this.props.height })}}>
					<div style={styles.filter}>
						<Link to={"/projects"} onClick={() => this.setState({ filter: this.state.filter === "lead" ? null : "lead" })} style={{...styles.bullet,...{backgroundColor: this.status("lead").color}, ...(this.state.filter === "lead" ? styles.toggle : {})}}>{this.state.filter === "lead" ? <i className="material-icons" style={styles.toggleicon}>close</i> : null}</Link>
						<Link to={"/projects"} onClick={() => this.setState({ filter: this.state.filter === "prebid" ? null : "prebid" })} style={{...styles.bullet,...{backgroundColor: this.status("prebid").color}, ...(this.state.filter === "prebid" ? styles.toggle : {})}}>{this.state.filter === "prebid" ? <i className="material-icons" style={styles.toggleicon}>close</i> : null}</Link>
						<Link to={"/projects"} onClick={() => this.setState({ filter: this.state.filter === "bid" ? null : "bid" })} style={{...styles.bullet,...{backgroundColor: this.status("bid").color}, ...(this.state.filter === "bid" ? styles.toggle : {})}}>{this.state.filter === "bid" ? <i className="material-icons" style={styles.toggleicon}>close</i> : null}</Link>
						<Link to={"/projects"} onClick={() => this.setState({ filter: this.state.filter === "invoice" ? null : "invoice" })} style={{...styles.bullet,...{backgroundColor: this.status("invoice").color}, ...(this.state.filter === "invoice" ? styles.toggle : {})}}>{this.state.filter === "invoice" ? <i className="material-icons" style={styles.toggleicon}>close</i> : null}</Link>
						<Link to={"/projects"} onClick={() => this.setState({ filter: this.state.filter === "project" ? null : "project" })} style={{...styles.bullet,...{backgroundColor: this.status("project").color}, ...(this.state.filter === "project" ? styles.toggle : {})}}>{this.state.filter === "project" ? <i className="material-icons" style={styles.toggleicon}>close</i> : null}</Link>
						<Link to={"/projects"} onClick={() => this.setState({ filter: this.state.filter === "after" ? null : "after" })} style={{...styles.bullet,...{backgroundColor: this.status("after").color}, ...(this.state.filter === "after" ? styles.toggle : {})}}>{this.state.filter === "after" ? <i className="material-icons" style={styles.toggleicon}>close</i> : null}</Link>
						<Link to={"/projects"} onClick={() => this.setState({ filter: this.state.filter === "deleted" ? null : "deleted" })} style={{...styles.bullet,...{backgroundColor: this.status("deleted").color}, ...(this.state.filter === "deleted" ? styles.toggle : {})}}>{this.state.filter === "deleted" ? <i className="material-icons" style={styles.toggleicon}>close</i> : null}</Link>
					</div>
					<FirestoreCollection
						path="projects"
						filter={this.props.profile.role === "superadmin" ? (this.state.filter !== null ? ["status", "==", this.state.filter] : null) : (this.state.filter !== null ? [["subsidiary.id", "==", this.props.profile.subsidiary.id], ["status", "==", this.state.filter]] : ["subsidiary.id", "==", this.props.profile.subsidiary.id])}
						/*sort={this.state.filter !== null ? null : "updated:desc"}*/
						render={({isLoading, error, data}) => this.renderItem(isLoading, error, data)}
					/>
					<div style={{...styles.search, ...(this.props.width < 767 ? { width: "100%", left: "0px", right: "0px" } : { width: "299px" })}}>
						<input type="text" style={styles.input} value={this.state.search} placeholder="Buscar" onChange={(e) => this.setState({ search: e.target.value })}/>
						{this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <a onClick={() => this.setState({ modal: "new" })}><i className="material-icons">add</i></a> : null}
					</div>
				</div>
				<Route path="/projects/:id" render={(props) => {
					if(props.match.params.id == "edit"){
						return null;
					}
					return <Project width={this.props.width} height={this.props.height} user={this.props.user} profile={this.props.profile}/>
				}} />
				<Modal isOpen={this.state.modal ? true : false} style={styles.modal} onRequestClose={(goto) => this.setState({ modal: null })}>
					<EditProject 
						id={this.state.modal} 
						user={this.props.user}
						profile={this.props.profile}
						close={(goto) => this.setState({ modal: null }, () => goto ? this.props.history.push("/projects/" + goto) : null)}
						/>
				</Modal>
            </div>
		);
	}

	status(status){
        switch(status){
            case "lead":
            return {
                "label": "Lead",
                "color": "#bdbdbd"
			}
			
			case "prebid":
            return {
                "label": "Pré-Proposta",
                "color": "#fd9c00"
            }
            
            case "bid":
            return {
                "label": "Proposta",
                "color": "#fdc900"
            }

            case "invoice":
            return {
                "label": "Fatura",
                "color": "#00c0fd"
            }

            case "project":
            return {
                "label": "Projeto",
                "color": "#32e100"
            }

            case "after":
            return {
                "label": "Pós-venda",
                "color": "#6c757d"
			}
			
			case "deleted":
            return {
                "label": "Deletado",
                "color": "#ff5f5f"
            }
        }
    }
}

const styles = {
	body: {
		display: "flex",
		flexDirection: "row",
		overflowX: "hidden",
		overflowY: "auto",
	},
	row: {
		width: "100%",
	},
	list: {
		position: "relative",
		padding: "60px 0px 70px 0px",
		overflowY: "auto"
	},
	search: {
		position: "fixed",
		bottom: "0px",
		//left: "0px",
		//right: "0px",
		height: "70px",
		padding: "15px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		fontWeight: 300,
		backgroundColor: "#f8f8f8"
	},
	filter: {
		position: "absolute",
		top: "5px",
		left: "0px",
		right: "0px",
		height: "50px",
		padding: "15px 15px 10px 15px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-around",
		alignItems: "center",
		fontWeight: 300,
		backgroundColor: "#f8f8f8"
	},
	bullet: {
		width: "20px",
		height: "20px",
		borderRadius: "10px",
		backgroundColor: "#CCCCCC"
	},
	toggle: {
		border: "2px solid rgba(0,0,0,.3)",
		color: "rgba(0,0,0,.3)"
	},
	toggleicon: {
		fontSize: "22px",
		position: "relative",
		top: "-3px",
		left: "-3px"
	},
	input: {
		padding: "10px 20px",
		marginRight: "15px",
		backgroundColor: "#DDDDDD",
		border: "none",
		borderRadius: "25px",
		color: "#555555",
		flex: 1
	},
	item: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		borderBottom: "1px solid rgba(0,0,0,.05)",
		//borderRadius: "7px",
		//margin: "15px 15px 0px 15px",
		padding: "20px 15px",
		//fontSize: "14px",
		fontWeight: 300,
		color: "#999999"
	},
	active: {
		backgroundColor: "#FFFFFF",
		color: "#555555"
	},
	badge: {
		backgroundColor: "#32e100FF",
		color: "#FFF",
		padding: "5px 10px",
		borderRadius: "16px",
		fontSize: "12px"
	},
	subtitle: { 
		color: "#999999", 
		fontSize: "10px", 
		textTransform: "uppercase", 
		margin: "0px" 
	},
	modal: {
		overlay: {
            backgroundColor: "rgba(0,0,0,.5)",
            overflowY: "auto"
		},
		content: {
            margin: "0px auto",
            padding: "0px",
            maxWidth: "400px",
            minWidth: "300px",
		}
	},
	loading: {
		padding: "30px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
}

export default Projects;

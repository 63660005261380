import React from "react";
import { Link, withRouter } from "react-router-dom";
import MDSpinner from 'react-md-spinner';
import * as firebase from 'firebase';
import { FirestoreDocument } from 'react-firestore';
import Modal from 'react-modal';
import EditItem from "./EditItem";

class Item extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			modal: null
        };
        this.delete = this.delete.bind(this);
    }

    delete(){
        if(window.confirm("Deseja deletar este item do catálogo?")){
            this.props.history.push("/items/")
            firebase.firestore().collection('items').doc(this.props.match.params.id).delete()
        }
    }

	render() {
		return (
			<div style={{...styles.body, ...{width: this.props.width - 371}, ...(this.props.width < 767 ? { width: this.props.width } : {})}}>
                <FirestoreDocument
                path={"items/" + this.props.match.params.id}
                render={({isLoading, error, data}) => {
                    if(!isLoading) {
                        return <div style={styles.main}>
                            <div style={styles.header}>
                                <h3 style={styles.title}>{data.category + " " + data.brand + " " + data.model.toUpperCase()}</h3>
                            </div>
                            <div style={styles.canvas}>
                                <div style={styles.block}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <p><strong>Marca:</strong> {data.brand}</p>
                                            <p><strong>Modelo:</strong> {data.model}</p>
                                            {data.code ? <p><strong>EAN13:</strong> {data.code.value}</p> : null}
                                            <p><strong>Categoria:</strong> {data.category}</p>
                                            {data.department ? <p><strong>Departamento:</strong> {data.department}</p> : null}
                                        </div>
                                        <div className="col-md-6">
                                            {data.description ? <p><strong>Descrição:</strong></p> : null}
                                            <div dangerouslySetInnerHTML={{ __html: data.description ? data.description : "" }}></div>
                                            {data.supplier ? <FirestoreDocument
                                                path={"profiles/" + data.supplier}
                                                render={({isLoading, error, data}) => {
                                                    return isLoading ? null : <div>
                                                        <p><strong>Fornecedor</strong></p>
                                                        <p>{data.email}</p>
                                                        <p>{data.phone}</p>
                                                        <p>{data.address}</p>
                                                        {data.location ? <p>{data.location.postalcode}, {data.location.city}, {data.location.state}, {data.location.country}</p> : null}
                                                    </div>
                                                }}
                                            /> : null}
                                        </div>
                                    </div>
                                </div>
                                <div style={styles.block}>
                                    <div className="row">
                                        {this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <div className="col-md-4">
                                            <p className="nomargin">Custo</p>
                                            <p style={styles.cost}>{(data.cost / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                        </div> : null}
                                        {this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <div className="col-md-4">
                                            <p className="nomargin">Margem de lucro</p>
                                            <p style={styles.cost}>{percent(data.cost, data.amount)}%</p>
                                        </div> : null}
                                        <div className="col-md-4">
                                            <p className="nomargin">Valor</p>
                                            <p style={styles.total}>{(data.amount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                        </div>
                                    </div>
                                </div>
                                {this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <div style={{...styles.actions, ...{width: this.props.width - 690}, ...(this.props.width < 767 ? { bottom: "70px", width: this.props.width} : {})}}>
                                    <nav className="nav nav-pills nav-justified">
                                        <a href="#" onClick={() => this.setState({ modal: data.id })} className="nav-link" >Editar</a>
                                        <a href="#" onClick={this.delete} className="nav-link">Deletar</a>
                                    </nav>
                                </div> : null}
                            </div>
                        </div>
                    } else {
                        return null
                    }
                }}/>
                <Modal isOpen={this.state.modal ? true : false} style={styles.modal} onRequestClose={() => this.setState({ modal: null })}>
					<EditItem id={this.state.modal} close={() => this.setState({ modal: null })}/>
				</Modal>
            </div>
		);
	}
}

const styles = {
	body: {
        //borderLeft: "1px solid #EDEDED",
        display: "flex",
        flexDirection: "row",
        width: "100%",
        maxWidth: "700px"
    },
    header: {
        padding: "15px",
        height: "80px",
        display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
    },
    title: {
        fontSize: "24px",
        fontWeight: 300,
        margin: "0px",
    },
    canvas: {
        padding: "15px",
    },
    main: {
        flex: 1,
        overflowX: "hidden",
        overflowY: "auto",
        paddingBottom: "85px"
    },
    block: {
        marginBottom: "30px"
    },
    row: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    column: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },
    list: {
        border: "1px solid #EDEDED",
        borderRadius: "7px",
        //backgroundColor: "#FFFFFF"
    },
    item: {
        padding: "15px",
        borderBottom: "1px solid #EDEDED",
        alignItems: "center",
        cursor: "pointer"
    },
    checkbox: {
        marginRight: "10px"
    },
    unit: {
        backgroundColor: "#EDEDED",
		color: "#444444",
		padding: "5px 10px",
		borderRadius: "16px",
        fontSize: "12px",
        marginRight: "10px",
        //marginLeft: "10px"
    },
    cost: {
        fontSize: "20px",
        fontWeight: "500",
        color: "#999999"
    },
    total: {
        fontSize: "28px",
        fontWeight: "500",
    },
    actions: {
        position: "absolute",
        bottom: "0px",
        left: "375px",
        padding: "15px 15px",
        backgroundColor: "#F7F7F7",
    },
    listactions: { 
        backgroundColor: "#FFF", 
        borderRadius: "0px 0px 5px 5px", 
        justifyContent: "center"
    },
    listactionsaction: {
        marginLeft: "15px"
    },
    modal: {
		overlay: {
            backgroundColor: "rgba(0,0,0,.5)",
            overflowY: "auto"
		},
		content: {
            margin: "0px auto",
            padding: "0px",
            maxWidth: "400px",
            minWidth: "300px",
		}
	}
}

const percent = (cost, amount) => {
    if(cost == 0 || amount == 0){
        return 0
    } else {
        //return Math.abs(((cost - amount) / cost) * 100).toFixed(0)
        return Math.abs(((1 - (cost / amount)) * 100)).toFixed(0)
    }
}

export default withRouter(Item);

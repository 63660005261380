import React from "react";
import { Link } from "react-router-dom";
import * as firebase from 'firebase';
import { FirestoreCollection } from 'react-firestore';
import Linkifier from 'react-linkifier';


class Logger extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			message: ""
        };
    }
    componentDidUpdate() {
        if(this.scroll){
            this.scroll.scrollTo({ behavior: 'smooth' });
        }
    }
    send(){
        let message = this.state.message;
        this.setState({
            message: ""
        }, () => {
            let payload = {
                "user": this.props.user.uid,
                "profile": {
                    "name": this.props.profile.name,
                    "photo": this.props.profile.photo
                },
                "project": this.props.project.id,
                "message": message,
                "created": new Date()
            }
            firebase.firestore().collection('messages').add(payload).catch((e) => {
                this.setState({
                    message: message
                })
                alert("Ocorreu um erro ao enviar sua mensagem")
            })
        })
    }

    handle(e){
        if(e.key === 'Enter') {
          this.send()
        }
    }

    renderMessage(isLoading, error, data){
        return data.sort((a, b) => new Date(a.created.seconds) - new Date(b.created.seconds)).map((message, i) => {
            return <li style={message.user ? message.user === this.props.user.id ? styles.left : styles.right : styles.neutral} key={i}>
                <span style={message.user ? message.user === this.props.user.id ? styles.they : styles.me : styles.neutral} className="chatmessage">{message.user ? message.user === this.props.user.id ? <strong>{message.profile.name}:</strong> : null : null} <Linkifier renderer={RenderLink}>{message.message}</Linkifier></span>
            </li>
        });
    }

	render() {
		return (
			<div style={styles.body}>
                <ul style={styles.scroll}>
                    <FirestoreCollection
                        path="messages"
                        filter={["project", "==", this.props.project.id]}
                        render={({isLoading, error, data}) => this.renderMessage(isLoading, error, data)}
                    />
                    <span ref={e => { this.scroll = e }}></span>
                </ul>
                <div style={styles.composer}>
                    <input style={styles.input} placeholder="Sua mensagem" value={this.state.message} onChange={(e) => this.setState({ message: e.target.value })} onKeyDown={(e) => this.handle(e)}/>
                    <button style={styles.send} onClick={() => this.send()}><i className="material-icons">send</i></button>
                </div>
            </div>
		);
	}
}

const RenderLink = ({href, children}) => (
    <a href={href} style={styles.link} target="_blank">
        {new URL(href).hostname}
        {/*children.length > 20 ? (children.substring(0, 20) + "...") : children*/}
    </a>
);

const styles = {
	body: {
        padding: "0px",
        paddingBottom: "67px",
        paddingRight: "15px",
        paddingLeft: "15px",
        margin: "0px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end"
    },
    scroll: {
        overflowX: "hidden",
        overflowY: "auto",
        margin: "0px",
        padding: "0px"
    },
    composer: {
        position: "fixed",
        bottom: "0px",
        height: "67px",
        padding: "15px 0px",
        paddingRight: "15px",
        width: "300px",
        backgroundColor: "#F7F7F7"
    },
    input: {
        padding: "7px 18px",
        paddingRight: "32px",
		width: "calc(100% - 15px)",
        backgroundColor: "#FFFFFF",
        border: "1px solid #EDEDED",
		borderRadius: "25px",
		color: "#555555",
		flex: 1
    },
    left: {
        listStyle: "none",
        display: "block",
        marginBottom: "5px",
        textAlign: "left"
    },
    right: {
        listStyle: "none",
        display: "block",
        marginBottom: "5px",
        textAlign: "right"
    },
    me: {
        display: "inline-block",
        padding: "7px 18px",
        marginLeft: "30px",
        backgroundColor: "#999999",
        borderRadius: "18px",
        color: "#FFFFFF",
    },
    they: {
        display: "inline-block",
        padding: "7px 18px",
        marginRight: "30px",
        backgroundColor: "#EDEDED",
        borderRadius: "18px",
        color: "#555",
    },
    neutral: {
        display: "inline-block",
        width: "100%",
        padding: "7px",
        fontSize: "12px",
        textAlign: "center",
        backgroundColor: "transparent",
        color: "#CCCCCC",
    },
    link: {
        //border: "1px solid #FFFFFF",
        //borderRadius: "4px",
        //padding: "1px 5px"
        textDecoration: "underline"
    },
    send: {
        position: "absolute",
        backgroundColor: "transparent",
        border: "none",
        right: "30px",
        top: "22px",
        borderRadius: "50%",
        cursor: "pointer"
    }
}

export default Logger;

import React from "react";
import { Link, Route, withRouter } from "react-router-dom";
import { FirestoreCollection } from 'react-firestore';
import MDSpinner from 'react-md-spinner';
import Modal from 'react-modal';
import EditItem from "./EditItem";

import Item from './Item';

class Items extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
			modal: null,
			search: ""
		};
	}
	renderItem(isLoading, error, data){
		var grouped;
		if(isLoading){
			return <div style={styles.loading}><MDSpinner style={{ marginLeft: "15px" }} singleColor="#999999" size="24"/></div>
		} else {
			grouped = groupBy(this.state.search.length > 0 ? data.filter((i) => i.category.toLowerCase().search(this.state.search.toLowerCase()) >= 0 || i.brand.toLowerCase().search(this.state.search.toLowerCase()) >= 0 || i.model.toLowerCase().search(this.state.search.toLowerCase()) >= 0 || i.department.toLowerCase().search(this.state.search.toLowerCase()) >= 0) : data, 'department');
			//console.log(grouped)
			return Object.keys(grouped).map((key, i) => {
				return <div key={i}>
					<div style={styles.division}>{key}</div>
					{grouped[key].map((item, i) => {
						return <Link to={"/items/" + item.id} style={{...styles.item, ...(this.props.location.pathname === ("/items/" + item.id) ? styles.active : {})}} key={i}>
						<div>
							<p style={styles.name}>{item.category + " " + item.brand}</p>
							<p style={{...styles.name, ...{ color: "#999999" }}}>{item.model}</p>
						</div>
					</Link>
					})}
				</div>
			})
			
		}
	}
	render() {
		return (
			<div style={{...styles.body, ...(this.props.width < 767 ? { width: "100%" } : { width: this.props.width - 71 })}}>
				<div style={{...styles.list, ...(this.props.width < 767 ? { width: this.props.width, height: this.props.height - 70 } : { width: "300px", borderRight: "1px solid #EDEDED", height: this.props.height })}}>
					<div style={{...styles.search, ...(this.props.width < 767 ? {left: "0px", right: "0px" } : { width: "299px" })}}>
						<input type="text" style={styles.input} value={this.state.search} placeholder="Buscar" onChange={(e) => this.setState({ search: e.target.value })}/>
						{this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <a href="#" onClick={() => this.setState({ modal: "new" })}><i className="material-icons">add</i></a> : null}
					</div>
					<FirestoreCollection
						path="items"
						render={({isLoading, error, data}) => this.renderItem(isLoading, error, data)}
					/>
				</div>
				<Route path="/items/:id" render={(props) => {
					if(props.match.params.id == "edit"){
						return null;
					}
					return <Item width={this.props.width} height={this.props.height}  profile={this.props.profile}/>
				}} />
				<Modal isOpen={this.state.modal ? true : false} style={styles.modal} onRequestClose={(goto) => this.setState({ modal: null })}>
					<EditItem id={this.state.modal} close={(goto) => this.setState({ modal: null }, () => goto ? this.props.history.push("/items/" + goto) : null)}/>
				</Modal>
            </div>
		);
	}
}

const groupBy = function(xs, key) {
	return xs.reduce(function(rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
};

const styles = {
	body: {
		display: "flex",
		flexDirection: "row",
	},
	search: {
		position: "fixed",
		bottom: "0px",
		//left: "0px",
		//right: "0px",
		height: "70px",
		padding: "15px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		fontWeight: 300,
		backgroundColor: "#f8f8f8"
	},
	list: {
		position: "relative",
		overflowY: "auto",
		padding: "0px 0px 70px 0px",
	},
	input: {
		padding: "10px 20px",
		marginRight: "15px",
		backgroundColor: "#DDDDDD",
		border: "none",
		borderRadius: "25px",
		color: "#555555",
		flex: 1
	},
	item: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		borderBottom: "1px solid rgba(0,0,0,.05)",
		//borderRadius: "7px",
		//margin: "15px 15px 0px 15px",
		padding: "15px",
		fontWeight: 300,
		color: "#999999"
	},
	active: {
		backgroundColor: "#FFFFFF",
		color: "#555555"
	},
	division: {
		padding: "3px 15px",
		fontSize: "10px",
		backgroundColor: "rgba(0,0,0,.05)",
		color: "#555555",
		textTransform: "uppercase"
	},
	badge: {
		backgroundColor: "#32e100FF",
		color: "#FFF",
		padding: "5px 10px",
		borderRadius: "16px",
		fontSize: "12px"
	},
	loading: {
		padding: "30px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	name: {
		margin: "0px"
	},
	modal: {
		overlay: {
            backgroundColor: "rgba(0,0,0,.5)",
            overflowY: "auto"
		},
		content: {
            margin: "0px auto",
            padding: "0px",
            maxWidth: "400px",
            minWidth: "300px",
		}
	}
}

export default withRouter(Items);

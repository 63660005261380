import React from "react";
import { Link, Route } from "react-router-dom";
import * as firebase from 'firebase';
import MDSpinner from 'react-md-spinner';
import Countries from '../subsidiaries/Countries.js';
import MaskedInput from 'react-maskedinput'

class AddProject extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
            working: true,
            name: "",
            email: "",
            phone: "",
            registry: "",
            address: "",
            postalcode: "",
            city: "",
            state: "",
            country: "Brasil",
            status: "lead",
            team: [],
            list: [],
            payment: null,
            states: [{"name": "Acre"},{"name": "Alagoas"},{"name": "Amapá"},{"name": "Amazonas"},{"name": "Bahia"},{"name": "Ceará"},{"name": "Distrito Federal"},{"name": "Espírito Santo"},{"name": "Goiás"},{"name": "Maranhão"},{"name": "Mato Grosso"},{"name": "Mato Grosso do Sul"},{"name": "Minas Gerais"},{"name": "Pará"},{"name": "Paraíba"},{"name": "Paraná"},{"name": "Pernambuco"},{"name": "Piauí"},{"name": "Rio de Janeiro"},{"name": "Rio Grande do Norte"},{"name": "Rio Grande do Sul"},{"name": "Rondônia"},{"name": "Roraima"},{"name": "Santa Catarina"},{"name": "São Paulo"},{"name": "Sergipe"},{"name": "Tocantins"}],
        };
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
    }
    componentDidMount(){
        if(this.props.id !== "new"){
            firebase.firestore().collection('projects').doc(this.props.id).get().then((item) => {
                this.setState({
                    working: false,
                    name: item.data().client ? item.data().client.name : "",
                    email: item.data().client ? item.data().client.email : "",
                    phone: item.data().client ? item.data().client.phone : "",
                    registry: item.data().client.registry ? item.data().client.registry : "",
                    address: item.data().client ? item.data().client.location ? item.data().client.location.address : "" : "",
                    postalcode: item.data().client ? item.data().client.location ? item.data().client.location.postalcode : "" : "",
                    city: item.data().client ? item.data().client.location ? item.data().client.location.city : "" : "",
                    state: item.data().client ? item.data().client.location ? item.data().client.location.state : "" : "",
                    country: item.data().client ? item.data().client.location?  item.data().client.location.country : "" : "",
                    status: item.data().status,
                    team: item.data().team,
                    list: item.data().list,
                    payment: item.data().payment
                })
            });
        } else {
            this.setState({
                working: false
            })
        }
    }
    save(){
        //console.log(this.state)
        
        if(this.state.name !== ""){
            this.setState({
                working: true
            })
            var payload = {
                "client": {
                    "name": this.state.name,
                    "email": this.state.email,
                    "phone": this.state.phone,
                    "registy": this.state.registry,
                    "location": {
                        "address": this.state.address,
                        "postalcode": this.state.postalcode,
                        "city": this.state.city,
                        "state": this.state.state,
                        "country": this.state.country
                    },
                },
                "status": this.state.status,
                "team": this.state.team,
                "list": this.state.list,
                "payment": this.state.payment,
                "updated": new Date()
            }
            if(this.props.id === "new"){
                payload['user'] = this.props.user.uid;
                payload['profile'] = {
                    "name": this.props.profile.name,
                    "photo": this.props.profile.photo ? this.props.profile.photo : null
                };
                payload['subsidiary'] = this.props.profile.subsidiary;
                payload['departments'] = {};
                payload['intro'] = "Prezados,<br/><br/>Submetemos à sua apreciação uma proposta de solução em automação de iluminação, de cortinas motorizadas, de áudio e vídeo e de aparelhos condicionadores de ar, bem como de equipamentos de home theater e sonorização de ambientes. Após recebimento do projeto luminotécnico, fizemos o levantamento dos requisitos e apresentamos a presente proposta, de modo a atender suas expectativas. As soluções apresentadas visam a oferecer mais conforto, praticidade e segurança para seu imóvel, além de estilo e sofisticação.<br/><br/>A HOMMAX Ambientes Inteligentes é especialista em automação e sonorização e tem como proposta oferecer novas experiências aos seus clientes e tornar a interação entre pessoas e ambientes uma vivência única e diferenciada. Por meio de um novo modelo de automação e da utilização de sistemas que contam com o que há de mais moderno e inovador no mercado, a HOMMAX cria soluções inteligentes e práticas.<br/><br/>A empresa oferece uma linha completa de serviços nos ramos da automação residencial, predial, corporativa e de ambientes em geral. As soluções são formatadas de acordo com a necessidade específica de cada cliente, gerando resultados surpreendentes que superam expectativas.<br/><br/>Mais informações: <a href='https://hommax.com.br'>www.hommax.com.br</a>";
                payload['footer'] = "Salientamos que os valores apresentados não incluem a execução da infraestrutura civil, elétrica e de dados da residência, as quais deverão estar prontas quando da fase de instalação dos equipamentos.<br/><br/>• Os cabos dos sistemas eletrônicos devem ser identificados conforme o projeto executivo.<br/><br/>• Caso deseje que a HOMMAX execute a infraestrutura e/ou identificação, solicite um orçamento em separado.<br/><br/>• Não estão inclusos neste orçamento os acabamentos elétricos, como teclas, espelhos, arandelas, lustres, lâmpadas, dentre outros.<br/><br/>• Também não estão incluídos no presente orçamento equipamentos como cortinas motorizadas, aparelhos condicionadores de ar, aparelhos televisores e similares.<br/><br/>• Os quadros de automação somente serão montados e instalados após todas as luzes da casa estarem ligadas e em funcionamento.<br/><br/>• Os equipamentos de rede de automação , matrizes de áudio e controladoras somente poderão ser instalados após a casa receber sinal de internet e estar livre de poeira.<br/><br/>• Antes de executar o forro, sugerimos chamar a Hommax para ajudar a identificar falhas e esquecimentos.<br/><br/>• Antes da pintura do teto, a Hommax deve ser acionada para instalar caixas de som, câmeras, repetidores de sinal de internet, etc.<br/>";
                firebase.firestore().collection('projects').add(payload).then((returned) => {
                    this.setState({
                        working: false
                    }, () => {
                        this.close(returned.id);
                    })
                }).catch((e) => {
                    console.error(e);
                    alert("Ocorreu um erro ao adicionar novo projeto.")
                    this.setState({
                        working: false
                    })
                })
            } else {
                firebase.firestore().collection('projects').doc(this.props.id).set(payload, { merge: true }).then(() => {
                    firebase.firestore().collection('messages').add({ "project": this.props.id, "title": this.state.name, "message": this.props.profile.name + " alterou os dados desta proposta", "created": new Date() })
                    this.setState({
                        working: false
                    }, () => {
                        this.close();
                    })
                }).catch((e) => {
                    console.error(e);
                    alert("Ocorreu um erro ao editar projeto.")
                    this.setState({
                        working: false
                    })
                })
            }
        } else {
            alert("Preencha todos os campos obrigatórios para continuar.")
        }
    }

    delete(){
        if(window.confirm("Deseja deletar este projeto?")){
            this.props.delete();
        }
    }

    close(goto = null){
        this.props.close(typeof goto === "String" ? goto : null);
    }
	render() {
		return (
			<div style={styles.body}>
                <div style={styles.header}>
                    <h3 style={styles.title}>Projeto</h3>
                </div>
                <form style={styles.form}>
                <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Status</label>
                        <select style={styles.select} value={this.state.status} onChange={(e) => this.setState({ status: e.target.value })}>
                            <option value={"lead"}>Lead</option>
                            <option value={"prebid"}>Pré-Proposta</option>
                            <option value={"bid"}>Proposta</option>
                            <option value={"invoice"}>Faturamento</option>
                            <option value={"project"}>Projeto</option>
                            <option value={"after"}>Pós-venda</option>
                        </select>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Nome *</label>
                        <input type="text" placeholder="Nome do cliente" style={styles.input} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>E-mail</label>
                        <input type="email" placeholder="nome@email.com" style={styles.input} value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Telefone</label>
                        <MaskedInput type="text" mask="(11)11111-1111" size="13" style={styles.input} value={this.state.phone.toString()} onChange={(e) => this.setState({ phone: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>CPF/CNPJ</label>
                        <input type="number" placeholder="999.999.999-99" style={styles.input} value={this.state.registry} onChange={(e) => this.setState({ registry: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Endereço</label>
                        <input type="text" placeholder="Fulano de Tal" style={styles.input} value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Cidade</label>
                        <input type="text" placeholder="Brasilia" style={styles.input} value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>CEP</label>
                        <MaskedInput type="text" placeholder="99999-999" mask="11111-111" size="9" style={styles.input} value={this.state.postalcode.toString()} onChange={(e) => this.setState({ postalcode: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>País</label>
                        <select style={styles.select} value={this.state.country} onChange={(e) => this.setState({ country: e.target.value })}>
                            {
                                Countries.map((country, i) => {
                                    return <option value={country.nome_pais} key={i}>{country.nome_pais}</option>
                                })
                            }
                        </select>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Estado</label>
                        {this.state.country == "Brasil" ? <select style={styles.select} value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })}>
                            {
                                this.state.states.map((state, i) => {
                                    return <option value={state.name} key={i}>{state.name}</option>
                                })
                            }
                        </select> : <input type="text" placeholder="Nome do estado" style={styles.input} value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })}/> }
                    </div>
                    <hr/>
                    
                    <div style={styles.actions}>
                    {this.props.id != "new" ? <button style={{...styles.button, ...styles.delete}} type="button" onClick={this.delete}>DELETAR</button> : null}
                    {this.state.working ? <MDSpinner style={{ marginLeft: "15px" }} singleColor="#32e100" size="24"/> : <button style={styles.button} onClick={this.save} type="button">SALVAR</button>}
                    </div>
                </form>
            </div>
		);
	}
}

const styles = {
	body: {
        
    },
    header: {
        height: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        backgroundColor: "#CCCCCC",
        color: "#999999"
    },
    title: {
        fontSize: "16px",
        textTransform: "uppercase",
        fontWeight: "300",
        margin: "0px",
        color: "#FFFFFF"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "50px"
    },
    field: {
        position: "relative",
    },
    label: {
        position: "absolute",
        top: "-7px",
        left: "10px",
        fontSize: "10px",
        padding: "0px 5px",
        backgroundColor: "#FFFFFF",
        color: "#CCCCCC",
        textTransform: "uppercase"
    },
    input: {
        border: "none",
        padding: "15px",
        paddingBottom: "20px",
        width: "100%"
    },
    select: {
        border: "none",
        margin: "15px 8px",
        paddingBottom: "20px",
        width: "calc(100% - 30px)",
        backgroundColor: "#FFFFFF"
    },
    actions: {
        padding: "15px",
        textAlign: "right"
    },
    button: {
		backgroundColor: "rgba(50, 225, 0, 1.0)",
		color: "#FFF",
		padding: "5px 13px",
		borderRadius: "22px",
        fontSize: "12px",
        fontWeight: 300,
        border: "none",
        marginLeft: "15px"
    },
    delete: {
		backgroundColor: "#ff6868",
		color: "#FFFFFF",
    },
    alt: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		color: "#999999",
	},
}

export default AddProject;

import React from "react";
import { Link, withRouter } from "react-router-dom";
import * as firebase from 'firebase';
import { FirestoreCollection } from 'react-firestore';
import MDSpinner from 'react-md-spinner';
import Countries from './Countries.js';
import MaskedInput from 'react-maskedinput'

class EditSubsidiary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
            working: true,
            address: "",
            postalcode: "",
            city: "",
            state: "Distrito Federal",
            country: "Brasil",
            name: "",
            legalname: "",
            cnpj: "",
            admin: "",
            phone: "",
            states: [{"name": "Acre"},{"name": "Alagoas"},{"name": "Amapá"},{"name": "Amazonas"},{"name": "Bahia"},{"name": "Ceará"},{"name": "Distrito Federal"},{"name": "Espírito Santo"},{"name": "Goiás"},{"name": "Maranhão"},{"name": "Mato Grosso"},{"name": "Mato Grosso do Sul"},{"name": "Minas Gerais"},{"name": "Pará"},{"name": "Paraíba"},{"name": "Paraná"},{"name": "Pernambuco"},{"name": "Piauí"},{"name": "Rio de Janeiro"},{"name": "Rio Grande do Norte"},{"name": "Rio Grande do Sul"},{"name": "Rondônia"},{"name": "Roraima"},{"name": "Santa Catarina"},{"name": "São Paulo"},{"name": "Sergipe"},{"name": "Tocantins"}],
        };
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount(){
        if(this.props.id !== "new"){
            firebase.firestore().collection('subsidiaries').doc(this.props.id).get().then((data) => {
                this.setState({
                    working: false,
                    address: data.data().location.address,
                    postalcode: data.data().location.postalcode,
                    city: data.data().location.city,
                    state: data.data().location.state,
                    country: data.data().location.country,
                    name: data.data().legal.name,
                    legalname: data.data().legal.legalname,
                    cnpj: data.data().legal.cnpj,
                    admin: data.data().admin,
                    phone: data.data().phone,
                })
            }).catch((e) => {
                console.error(e);
                alert("Ocorreu um erro ao obter dados da filial.")
                this.setState({
                    working: false
                })
            })
        } else {
            this.setState({
                working: false
            })
        }
    }

    save(){
        if(this.state.address !== "" && this.state.city !== "" && this.state.state !== "" && this.state.country !== "" && this.state.name !== "" && this.state.legalname !== "" && this.state.cnpj !== "" && this.state.admin !== ""){
            this.setState({
                working: true
            }, () => {
                var payload = {
                    "location": {
                        "address": this.state.address,
                        "postalcode": parseInt(this.state.postalcode),
                        "city": this.state.city,
                        "state": this.state.state,
                        "country": this.state.country
                    },
                    "legal": {
                        "name": this.state.name,
                        "legalname": this.state.legalname,
                        "cnpj": this.state.cnpj
                    },
                    "admin": this.state.admin,
                    "phone": this.state.phone
                }
                if(this.props.id !== "new"){
                    firebase.firestore().collection('subsidiaries').doc(this.props.id).set(payload, { merge: true }).then(() => {
                        this.setState({
                            working: false
                        }, () => {
                            this.close();
                        })
                    }).catch((e) => {
                        console.error(e);
                        alert("Ocorreu um erro ao salvar nova filial.")
                        this.setState({
                            working: false
                        })
                    })
                } else {
                    firebase.firestore().collection('subsidiaries').add(payload).then(() => {
                        this.setState({
                            working: false
                        }, () => {
                            this.close();
                        })
                    }).catch((e) => {
                        console.error(e);
                        alert("Ocorreu um erro ao salvar nova filial.")
                        this.setState({
                            working: false
                        })
                    })
                }
            })
        } else {
            alert("Preencha todos os campos para continuar.")
        }
    }

    close(){
        this.props.close();
    }

	render() {
		return (
			<div style={styles.body}>
                <div style={styles.header}>
                    <h3 style={styles.title}>FILIAL</h3>
                </div>
                <form style={styles.form}>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Endereço *</label>
                        <input type="text" placeholder="W3 Sul" style={styles.input} value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Cidade *</label>
                        <input type="text" placeholder="Brasilia" style={styles.input} value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>CEP</label>
                        <MaskedInput type="text" placeholder="99999-999" mask="11111-111" size="9" style={styles.input} value={this.state.postalcode.toString()} onChange={(e) => this.setState({ postalcode: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>País *</label>
                        <select style={styles.select} value={this.state.country} onChange={(e) => this.setState({ country: e.target.value })}>
                            {
                                Countries.map((country, i) => {
                                    return <option value={country.nome_pais} key={i}>{country.nome_pais}</option>
                                })
                            }
                        </select>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Estado *</label>
                        {this.state.country == "Brasil" ? <select style={styles.select} value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })}>
                            {
                                this.state.states.map((state, i) => {
                                    return <option value={state.name} key={i}>{state.name}</option>
                                })
                            }
                        </select> : <input type="text" placeholder="Nome do estado" style={styles.input} value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })}/> }
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Nome fantasia *</label>
                        <input type="text" placeholder="Hommax Brasilia" style={styles.input} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Pessoa Jurídica *</label>
                        <input type="text" placeholder="Hommax Brasilia, Ltda" style={styles.input} value={this.state.legalname} onChange={(e) => this.setState({ legalname: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>CNPJ *</label>
                        <MaskedInput type="text" mask="11.111.111/1111-11" size="18" style={styles.input} value={this.state.cnpj} onChange={(e) => this.setState({ cnpj: e.target.value })}/>
                        {/*<input type="number" placeholder="Cadastro Nacional de Pessoas Jurídicas" style={styles.input} value={this.state.cnpj} onChange={(e) => this.setState({ cnpj: e.target.value })}/>*/}
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Telefone fixo</label>
                        <MaskedInput type="text" mask="(11)1111-1111" size="12" style={styles.input} value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Administrador *</label>
                        <FirestoreCollection
                            path="profiles"
                            filter={["role", "==", "admin"]}
                            render={({isLoading, error, data}) => {
                                if(data != null){
                                    return <select style={styles.select} defaultValue={this.state.admin ? this.state.admin : ""} onChange={(e) => this.setState({ admin: e.target.value })}>
                                        <option value={""} key={"-1"}>{"Selecione um usário"}</option>
                                        {
                                            data.map((user, i) => {
                                                return <option value={user.id} key={i}>{user.name}</option>
                                            })
                                        }
                                    </select>
                                } else {
                                    return null
                                }
                            }}
                        />
                    </div>
                    <hr/>

                    <div style={styles.actions}>
                        <button style={{...styles.button, ...styles.alt}} type="button" onClick={this.close}>CANCELAR</button>
                        {this.state.working ? <MDSpinner style={{ marginLeft: "15px" }} singleColor="#32e100" size="24"/> : <button style={styles.button} onClick={this.save} type="button">SALVAR</button>}
                    </div>
                </form>
            </div>
		);
	}
}

const styles = {
	body: {
        
    },
    header: {
        height: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        backgroundColor: "#CCCCCC",
        color: "#999999",
    },
    title: {
        fontSize: "16px",
        textTransform: "uppercase",
        fontWeight: "300",
        margin: "0px",
        color: "#FFFFFF"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "50px"
    },
    field: {
        position: "relative",
    },
    label: {
        position: "absolute",
        top: "-7px",
        left: "10px",
        fontSize: "10px",
        padding: "0px 5px",
        backgroundColor: "#FFFFFF",
        color: "#CCCCCC",
        textTransform: "uppercase"
    },
    input: {
        border: "none",
        padding: "15px",
        paddingBottom: "20px",
        width: "100%"
    },
    select: {
        border: "none",
        margin: "15px 8px",
        paddingBottom: "20px",
        width: "calc(100% - 30px)",
        backgroundColor: "#FFFFFF"
    },
    actions: {
        padding: "15px",
        textAlign: "right"
    },
    button: {
		backgroundColor: "rgba(50, 225, 0, 1.0)",
		color: "#FFF",
		padding: "5px 13px",
		borderRadius: "22px",
        fontSize: "12px",
        fontWeight: 300,
        border: "none",
        marginLeft: "15px"
    },
    alt: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		color: "#999999",
	},
}

export default withRouter(EditSubsidiary);

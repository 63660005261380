import React from "react";
import { Link, withRouter } from "react-router-dom";
import * as firebase from 'firebase';
import MDSpinner from 'react-md-spinner';
import Moment from 'moment';

class EditPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
            actual: "",
            password: "",
            password2: "",
            working: false
        };
        this.close = this.close.bind(this);
    }

    save(){
        let that = this;
        if(this.state.password === this.state.password2){
            const credential = firebase.auth.EmailAuthProvider.credential(this.props.user.email, this.state.actual);
            this.setState({
                working: true
            }, () => {
                that.props.user.reauthenticateAndRetrieveDataWithCredential(credential).then(function() {
                    that.props.user.updatePassword(that.state.password).then(() => {
                        that.setState({
                            working: false
                        }, () => {
                            that.close();
                        });
                    }, (e) => {
                        console.error(e);
                        alert("Ocorreu um erro ao salvar alteração de senha.")
                        that.setState({
                            working: false
                        })
                    });
                }).catch(function(e) {
                    console.error(e);
                    alert("Ocorreu um problema ao logar com sua senha atual.")
                });
            });
        } else {
            alert("As senhas não conferem.")
        }   
    }

    close(){
        this.props.close();
    }

	render() {
		return (
			<div style={styles.body}>
                <div style={styles.header}>
                    <h3 style={styles.title}>Alterar senha</h3>
                </div>
                <form style={styles.form}>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Senha atual</label>
                        <input type="password" placeholder="Senha" style={styles.input} value={this.state.actual} onChange={(e) => this.setState({ actual: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Nova senha</label>
                        <input type="password" placeholder="Senha" style={styles.input} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Confirmar senha</label>
                        <input type="password" placeholder="Senha" style={styles.input} value={this.state.password2} onChange={(e) => this.setState({ password2: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.actions}>
                        <button style={{...styles.button, ...styles.alt}} type="button" onClick={this.close}>CANCELAR</button>
                        {this.state.working ? <MDSpinner style={{ marginLeft: "15px" }} singleColor="#32e100" size="24"/> : <button style={styles.button} onClick={this.save.bind(this)} type="button">ALTERAR</button>}
                    </div>
                </form>
            </div>
		);
	}
}

const styles = {
	body: {
        
    },
    header: {
        height: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        backgroundColor: "#CCCCCC",
        color: "#999999",
    },
    title: {
        fontSize: "16px",
        textTransform: "uppercase",
        fontWeight: "300",
        margin: "0px",
        color: "#FFFFFF"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "50px"
    },
    field: {
        position: "relative",
    },
    label: {
        position: "absolute",
        top: "-7px",
        left: "10px",
        fontSize: "10px",
        padding: "0px 5px",
        backgroundColor: "#FFFFFF",
        color: "#CCCCCC",
        textTransform: "uppercase"
    },
    input: {
        border: "none",
        padding: "15px",
        paddingBottom: "20px",
        width: "100%"
    },
    select: {
        border: "none",
        margin: "15px 8px",
        paddingBottom: "20px",
        width: "calc(100% - 30px)",
        backgroundColor: "#FFFFFF"
    },
    actions: {
        padding: "15px",
        textAlign: "right"
    },
    button: {
		backgroundColor: "rgba(50, 225, 0, 1.0)",
		color: "#FFF",
		padding: "5px 13px",
		borderRadius: "22px",
        fontSize: "12px",
        fontWeight: 300,
        border: "none",
        marginLeft: "15px"
    },
    alt: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		color: "#999999",
    },
    white: {
		backgroundColor: "#FFFFFF",
		color: "#999999",
    },
    row: {
        display: "flex",
        flexDirection: "row",
        padding: "15px",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    avatar: {
		width: "40px",
		height: "40px",
		borderRadius: "20px",
        backgroundColor: "#DDDDDD",
        backgroundSize: "cover",
        backgroundPosition: "center",
		marginRight: "15px"
	}
}

export default withRouter(EditPassword);

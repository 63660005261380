import React from "react";
import { Link, withRouter } from "react-router-dom";
import Logger from "./Logger.js";
import Modal from 'react-modal';
import * as firebase from 'firebase';
import { FirestoreDocument, FirestoreCollection } from 'react-firestore';
import MDSpinner from 'react-md-spinner';
import EditProject from "./EditProject";
import EditPayment from "./EditPayment";
import EditItem from "./EditItem";
import EditFolder from "./EditFolder";
import EditDepartment from "./EditDepartment";
import EditTexts from "./EditTexts";
import EditMember from "./EditMember";
import Email from "./Email";
import Numeral from 'numeral';

var timer;

class Project extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
            viewlogs: this.props.width > 767 ? true : false,
            item: null,
            department: null,
            payment: null,
            project: null,
            folder: null,
            email: false,
            text: false,
            member: null
        };
    }
    
    changeStatus(status, title){
        let payload = {
            "status": status,
            "updated": new Date()
        }
        firebase.firestore().collection('projects').doc(this.props.match.params.id).set(payload, { merge: true }).then(() => {
            firebase.firestore().collection('messages').add({ "project": this.props.match.params.id, "title": title, "message": this.props.profile.name + " alterou o status desta proposta para " + this.status(status).label, "created": new Date() })
        })
    }

    changeDiscount(discount){
        if(discount > -1 && discount < 50){
            let payload = {
                "discount": discount,
                "updated": new Date()
            }
            firebase.firestore().collection('projects').doc(this.props.match.params.id).set(payload, { merge: true }).then(() => {
                //firebase.firestore().collection('messages').add({ "project": this.props.match.params.id, "message": this.props.profile.name + " alterou o desconto desta proposta para " + discount + "%", "created": new Date() })
            })
        }
    }

    order(){
        if(window.confirm("Para cada item desta proposta será eviada uma solicitação de cotação ao seu respectivo fornecedor. Deseja continuar?")){

        }
    }

    copy(data){
        var payload = {
            "user": this.props.user.uid,
            "profile": {
                "name": this.props.profile.name,
                "photo": this.props.profile.photo ? this.props.profile.photo : null
            },
            "subsidiary": this.props.profile.subsidiary,
            "departments": {},
            "reference": this.props.match.params.id,
            "client": data.client,
            "status": "lead",
            "team": data.team,
            "list": [],
            "updated": new Date()
        }
        firebase.firestore().collection('projects').add(payload).then((returned) => {
            this.props.history.push("/projects/" + returned.id);
        }).catch((e) => {
            console.error(e);
            alert("Ocorreu um erro ao copiar projeto.")
        })
    }

    delete(){
        if(this.state.project){
            if(window.confirm("Deseja deletar este projeto?")){
                this.props.history.replace("/projects/")
                firebase.firestore().collection('projects').doc(this.props.match.params.id).set({ "status": "deleted" }, { merge: true }).then(() => {
                    this.setState({
                        project: null
                    });
                }).catch((e) => {
                    console.error(e);
                    alert("Ocorreu um erro ao deletar projeto.")
                })
            }
            
        }
        
    }

    removeMember(user){
        if(window.confirm("Deseja remover " + user.name + " do projeto?")){
            firebase.firestore().collection('projects').doc(this.props.match.params.id).collection('team').doc(user.id).delete().then((deleted) => {
                firebase.firestore().collection('messages').add({ "project": this.props.match.params.id, "message": user.name + " foi removido do projeto", "created": new Date() })
            }).catch((e) => {
                console.error(e);
                alert("Ocorreu um erro ao remover usuário do projeto.")
            })
        }
    }


    renderTeamMember(isLoading, error, data){
        if(!isLoading){
			return data.map((item, i) => {
				return <a onClick={() => this.removeMember(item)} style={{ float: "left", marginRight: "5px", marginBottom: "5px" }} key={i}><span style={{...styles.avatar, ...{ backgroundImage: 'url(' + item.photo + ')', marginLeft: '0px' }}} title={item.name }></span></a>
			});
		} else {
            return null
        }
    }

	render() {
		return (
			<div style={{...styles.body, ...{width: this.props.width - 371}, ...(this.props.width < 767 ? { width: this.props.width } : {})}}>
                <FirestoreDocument
                    path={"projects/" + this.props.match.params.id}
                    render={({isLoading, error, data}) => {
                        var sorted;
                        if(!isLoading && data != null) {
                            sorted = groupby(data.list, "department");
                            return <React.Fragment>
                                <div style={styles.main}>
                                    <div style={styles.header}>
                                        <h3 style={styles.title}>{data.client.name}</h3>
                                        <span style={styles.user}>
                                            <span style={{...styles.badge, ...{ backgroundColor: this.status(data.status).color }}}>{this.status(data.status).label.toUpperCase()}</span>
                                            {data.profile ? <span style={{...styles.avatar, ...{ backgroundImage: 'url(' + data.profile.photo + ')' }}} title={"Autor: " + data.profile.name }></span> : null}
                                        </span>
                                    </div>
                                    <div style={styles.canvas}>
                                        <div style={styles.block}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <FirestoreCollection
                                                        path={"projects/" + data.id + "/team"}
                                                        render={({isLoading, error, data}) => this.renderTeamMember(isLoading, error, data)}
                                                    />
                                                    {this.props.user.uid == data.user || this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <a onClick={() => this.setState({ member: "new" })} style={{ float: "left", marginRight: "5px", marginBottom: "5px" }}><span style={{...styles.avatar, ...{ display: "flex", alignItems: "center", justifyContent: "center", color: "#999999", margin: 0 }}} title={"Adicionar membro"}><i className="material-icons" style={{ top: 0 }}>add</i></span></a> : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={styles.block}>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    {data.client.phone ? <p>{data.client.email}</p> : null}
                                                    {data.client.phone ? <p>{data.client.phone}</p> : null}
                                                    {data.subsidiary ? <p>{data.subsidiary.name}</p> : null}
                                                </div>
                                                {data.client.location.address !== "" ? <div className="col-md-6">
                                                    <p>{data.client.location.address}</p>
                                                    <p>{data.client.location.postalcode ? data.client.location.postalcode : null}</p>
                                                    <p>{data.client.location.city}, {data.client.location.state}, {data.client.location.country}</p>
                                                </div> : null}
                                            </div>
                                        </div>
                                        <div style={{...styles.block, ...styles.list}}>
                                            {sorted != null ? Object.keys(sorted).map((key, y) => {
                                                return <div key={y}>
                                                    <div style={styles.department}>{key} {this.props.profile.role !== "staff" ? <a onClick={() => this.setState({ department: key })}><i className="material-icons float-right">notes</i></a> : null}</div>
                                                    <div className="list">
                                                        {sorted[key].map((item, i) => {
                                                            return <a style={{...styles.column, ...styles.item}} onClick={this.props.profile.role !== "staff" ? () => this.setState({ item: item.id }) : null} key={i}>
                                                                <div style={{...styles.column, ...{ flex: 0.7, alignItems: "center" }}}>{item.status ? <span style={styles.status}>{this.getStatusName(item.status)}</span> : null} <span style={styles.unit}>{item.quantity}{this.getMeasureUnit(item.measure)}</span>{item.categoty} {item.brand} {item.model}</div>
                                                                <div style={{...styles.row, ...{ flex: 0.3, textAlign: "right" }}}>{((item.amount * item.quantity) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </div>
                                                            </a>
                                                        })}
                                                    </div>
                                                </div>;
                                            }) : <a style={{...styles.column, ...styles.item}}>
                                                <div style={{...styles.column, ...{ flex: 1, alignItems: "center", justifyContent: "center" }}}>Lista de produtos e serviços</div>
                                            </a>}
                                            {this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <div style={{...styles.column, ...styles.item, ...styles.listactions}}>
                                                <a onClick={() => this.setState({ item: "new" })}><i className="material-icons" style={styles.listactionsaction} title="Adicionar item">add_box</i></a>
                                                {/*<a onClick={() => this.order()}><i className="material-icons" style={styles.listactionsaction} title="Ordem de compra">shopping_cart</i></a>*/}
                                            </div> : null}
                                        </div>
                                        <div style={styles.block}>
                                            <div className="row">
                                                <div className="col-xl-6">
                                                    <div style={{...styles.block, ...styles.list}} className="list">
                                                        {data.payment != null ? data.payment.installments.map((item, i) => {
                                                            return <a style={{...styles.column, ...styles.item}} onClick={() => this.setState({ payment: i })} key={i}>
                                                                <div style={{...styles.column, ...{ flex: 0.7, alignItems: "center" }}}><i style={styles.checkbox} className="material-icons">{item.status === "paid" ? "check_box" : "check_box_outline_blank"}</i> {this.payment(item.type)}</div>
                                                                <div style={{...styles.row, ...{ flex: 0.3, textAlign: "right" }}}>{(item.amount / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} </div>
                                                            </a>
                                                        }) : <a style={{...styles.column, ...styles.item}}>
                                                            <div style={{...styles.column, ...{ flex: 1, alignItems: "center", justifyContent: "center" }}}>Pagamento</div>
                                                        </a>}
                                                        {this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <div style={{...styles.column, ...styles.item, ...styles.listactions}}>
                                                            <a onClick={() => this.setState({ payment: "new" })}><i className="material-icons" style={styles.listactionsaction} title="Adicionar item">add_box</i></a>
                                                        </div> : null}
                                                    </div>
                                                </div>
                                                <div className="col-xl-6">
                                                    <div style={{...styles.block, ...styles.list}} className="list">
                                                        {Object.keys(data.files ? data.files : {}).length > 0 ? Object.keys(data.files).map((key, i) => {
                                                            return <a style={{...styles.column, ...styles.item}} onClick={() => this.setState({ folder: key })} key={i}>
                                                                <div style={{...styles.column, ...{ flex: 0.7, alignItems: "center" }}}><i style={styles.checkbox} className="material-icons">folder</i> {capitalize(key.replace(/-/g, ' '))}</div>
                                                            </a>
                                                        }) : <a style={{...styles.column, ...styles.item}}>
                                                            <div style={{...styles.column, ...{ flex: 1, alignItems: "center", justifyContent: "center" }}}>Arquivos do projeto</div>
                                                        </a>}
                                                        {this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <div style={{...styles.column, ...styles.item, ...styles.listactions}}>
                                                            <a onClick={() => this.setState({ folder: "new" })}><i className="material-icons" style={styles.listactionsaction} title="Adicionar item">add_box</i></a>
                                                        </div> : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={styles.block}>
                                            <div className="row">
                                                {this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <div className="col-xl-4">
                                                    <p className="nomargin">Custo</p>
                                                    <p style={styles.cost}>{(this.calcCost(data.list) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                </div> : null}
                                                {this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <div className="col-xl-4">
                                                    <p className="nomargin">Margem de lucro</p>
                                                    <p style={styles.cost}>{percent(this.calcCost(data.list), this.calcValue(data.list))}%</p>
                                                </div> : null}
                                                <div className="col-xl-4">
                                                    {this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <div>
                                                        <p className="nomargin">Desconto</p>
                                                        <p style={styles.cost}><button style={styles.discountaction} onClick={() => this.changeDiscount((data.discount ? data.discount : 0) - 1)}><i className="material-icons" title="Diminuir desconto">arrow_back</i></button> <span style={{position: "relative", top: "-7px", display: "inline-block", width: "45px", textAlign: "center"}}>{data.discount ? data.discount : 0}%</span> <button style={styles.discountaction} onClick={() => this.changeDiscount((data.discount ? data.discount : 0) + 1)}><i className="material-icons" title="Diminuir desconto">arrow_forward</i></button></p>
                                                    </div> : null}
                                                    <p className="nomargin">Total</p>
                                                    {(data.discount ? data.discount : 0) > 0 ? <p style={{...styles.cost, ...{marginBottom: "0px"}}}>{((this.calcValue(data.list) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))}</p> : null}
                                                    {(data.discount ? data.discount : 0) > 0 ? <p style={{...styles.cost, ...{marginBottom: "0px"}}}>{((this.calcValue(data.list) * ((data.discount ? data.discount : 0) / 100)) / 100).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} -</p> : null}
                                                    <p style={styles.total}>{((this.calcValue(data.list) / 100) - ((this.calcValue(data.list) * ((data.discount ? data.discount : 0) / 100)) / 100)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <div style={{...styles.actions, ...{width: this.props.width - 690}, ...(this.props.width < 767 ? { bottom: "70px", width: this.props.width} : {})}}>
                                            <nav className="nav nav-pills nav-justified">
                                                <div className="btn-group">
                                                    <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" id="status">Status</a>
                                                    <div className="dropdown-menu" aria-labelledby="status">
                                                        <a className="dropdown-item" onClick={() => this.changeStatus("lead", data.client.name)}>Lead</a>
                                                        <a className="dropdown-item" onClick={() => this.changeStatus("prebid", data.client.name)}>Pré-Proposta</a>
                                                        <a className="dropdown-item" onClick={() => this.changeStatus("bid", data.client.name)}>Proposta</a>
                                                        <a className="dropdown-item" onClick={() => this.changeStatus("invoice", data.client.name)}>Fatura</a>
                                                        <a className="dropdown-item" onClick={() => this.changeStatus("project", data.client.name)}>Projeto</a>
                                                        <a className="dropdown-item" onClick={() => this.changeStatus("after", data.client.name)}>Pós-venda</a>
                                                    </div>
                                                </div>
                                                <div className="btn-group">
                                                    <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" id="expo">Exportar</a>
                                                    <div className="dropdown-menu" aria-labelledby="expo">
                                                        <a className="dropdown-item" href={"https://us-central1-hommax-44250.cloudfunctions.net/pdf?id=" + this.props.match.params.id + "&save=true"} target="_black">PDF</a>
                                                        <a className="dropdown-item" onClick={() => this.setState({ email: true })}>E-mail</a>
                                                        <a className="dropdown-item" onClick={() => this.copy(data)}>Criar cópia</a>
                                                    </div>
                                                </div>
                                                <div className="btn-group">
                                                    <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false" id="expo">Editar</a>
                                                    <div className="dropdown-menu" aria-labelledby="expo">
                                                        <a className="dropdown-item" onClick={() => this.setState({ text: true })}>Textos</a>
                                                        <a className="dropdown-item" onClick={() => this.setState({ project: "123" })}>Projeto</a>
                                                    </div>
                                                </div>
                                            </nav>
                                        </div> : null}
                                    </div>
                                </div>
                                {this.state.viewlogs ? <div style={styles.logs}>
                                    <Logger user={this.props.user} profile={this.props.profile} project={data}/>
                                </div> : null}
                                {this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <Modal isOpen={this.state.payment !== null ? true : false} style={styles.modal} onRequestClose={(goto) => this.setState({ payment: null })}>
                                    <EditPayment id={this.state.payment} project={data} close={() => this.setState({ payment: null })} profile={this.props.profile}/>
                                </Modal> : null}
                                <Modal isOpen={this.state.item !== null ? true : false} style={styles.modal} onRequestClose={(goto) => this.setState({ item: null })}>
                                    <EditItem id={this.state.item} project={data} close={() => this.setState({ item: null })} profile={this.props.profile}/>
                                </Modal>
                                {this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <Modal isOpen={this.state.project !== null ? true : false} style={styles.modal} onRequestClose={(goto) => this.setState({ project: null })}>
                                    <EditProject id={data.id} close={(goto) => this.setState({ project: null }, () => goto ? this.props.history.push("/projects/" + goto) : null)} delete={() => this.delete()} profile={this.props.profile}/>
                                </Modal> : null}
                                <Modal isOpen={this.state.folder !== null ? true : false} style={styles.modal} onRequestClose={(goto) => this.setState({ folder: null })}>
                                    <EditFolder project={data} id={this.state.folder} close={(open) => this.setState({ folder: open ? open : null })} profile={this.props.profile}/>
                                </Modal>
                                <Modal isOpen={this.state.email} style={styles.modal} onRequestClose={(goto) => this.setState({ email: false })}>
                                    <Email project={data} close={(open) => this.setState({ email: false })} profile={this.props.profile} user={this.props.user}/>
                                </Modal>
                                <Modal isOpen={this.state.department !== null ? true : false} style={styles.modal} onRequestClose={(goto) => this.setState({ department: null })}>
                                    <EditDepartment id={this.state.department} project={data} close={() => this.setState({ department: null })} profile={this.props.profile}/>
                                </Modal>
                                <Modal isOpen={this.state.member !== null ? true : false} style={styles.modal} onRequestClose={(goto) => this.setState({ member: null })}>
                                    <EditMember id={this.state.member} project={data} close={() => this.setState({ member: null })} profile={this.props.profile}/>
                                </Modal>
                                <Modal isOpen={this.state.text} style={styles.modal} onRequestClose={(goto) => this.setState({ text: false })}>
                                    <EditTexts project={data} close={() => this.setState({ text: false })} profile={this.props.profile}/>
                                </Modal>
                            </React.Fragment>
                        } else {
                            return null
                        }
                    }}
                />
            </div>
		);
    }
    calcCost(list){
        let total = 0;
        list.map((item) => {
            total += (item.cost * item.quantity);
        })
        return total;
    }
    calcValue(list){
        let total = 0;
        list.map((item) => {
            total += (item.amount * item.quantity);
        })
        return total;
    }
    calcProfit(list){
        let cost = this.calcCost(list);
        let amount = this.calcValue(list)
        return (cost > 0 ? cost : 0) / (amount > 0 ? amount : 1);
    }
    getMeasureUnit(unit){
        switch(unit){
            case "unit":
            return "UN";

            case "pair":
            return "PAR"

            case "meter":
            return "MT";

            case "hour":
            return "H";

            default:
            return unit.toUpperCase()
        }
    }
    getStatusName(status){
        switch(status){
            case "quoted":
            return "SOLICITADO";

            case "bought":
            return "COMPRADO";

            case "delivered":
            return "ENTREGUE";

            case "installed":
            return "INSTALADO";

            default:
            return status.toUpperCase()
        }
    }
    status(status){
        switch(status){
            case "lead":
            return {
                "label": "Lead",
                "color": "#bdbdbd"
            }

            case "prebid":
            return {
                "label": "Pré-Proposta",
                "color": "#fd9c00"
            }
            
            case "bid":
            return {
                "label": "Proposta",
                "color": "#fdc900"
            }

            case "invoice":
            return {
                "label": "Fatura",
                "color": "#00c0fd"
            }

            case "project":
            return {
                "label": "Projeto",
                "color": "#32e100"
            }

            case "after":
            return {
                "label": "Pós-venda",
                "color": "#6c757d"
            }

            case "deleted":
            return {
                "label": "Deletado",
                "color": "#ff5f5f"
            }
        }
    }

    payment(type){
        switch(type){
            case "cash":
            return "Dinheiro"
            
            case "check":
            return "Cheque"

            case "debit":
            return "Débito"

            case "credit":
            return "Crédito"

            case "transfer":
            return "Transferência"

            case "billet":
            return "Boleto"
        }
    }
}

const styles = {
	body: {
        //borderLeft: "1px solid #EDEDED",
        display: "flex",
        flexDirection: "row",
        overflowX: "hidden",
		overflowY: "hidden",
    },
    header: {
        padding: "15px",
        height: "80px",
        display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
    },
    title: {
        fontSize: "24px",
        fontWeight: 300,
        margin: "0px",
    },
    badge: {
		backgroundColor: "#32e100",
		color: "#FFFFFF",
		padding: "5px 10px",
		borderRadius: "16px",
        fontSize: "12px",
	},
    canvas: {
        padding: "0px 15px",
    },
    logs: {
        width: "300px",
    },
    main: {
        flex: 1,
        overflowX: "hidden",
        overflowY: "auto",
        paddingBottom: "85px"
    },
    block: {
        marginBottom: "30px"
    },
    row: {
        display: "flex",
        flexDirection: "column",
        flex: 1,
    },
    column: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
    },
    list: {
        border: "1px solid #EDEDED",
        borderRadius: "7px",
        backgroundColor: "#FFFFFF"
    },
    department: {
        fontSize: "12px",
        textTransform: "uppercase",
        padding: "15px",
        borderBottom: "1px solid #EDEDED",
        color: "#999999"
    },
    item: {
        padding: "15px",
        borderBottom: "1px solid #EDEDED",
        alignItems: "center",
        cursor: "pointer"
    },
    checkbox: {
        marginRight: "10px"
    },
    unit: {
        border: "1px solid #EDEDED",
        backgroundColor: "#EDEDED",
		color: "#444444",
		padding: "5px 10px",
		borderRadius: "16px",
        fontSize: "12px",
        marginRight: "10px",
    },
    status: {
        border: "1px solid #444444",
		color: "#444444",
		padding: "5px 10px",
		borderRadius: "16px",
        fontSize: "12px",
        marginRight: "10px",
    },
    cost: {
        fontSize: "20px",
        fontWeight: "500",
        color: "#999999"
    },
    total: {
        fontSize: "28px",
        fontWeight: "500",
    },
    actions: {
        position: "absolute",
        bottom: "0px",
        left: "375px",
        padding: "15px 15px",
        backgroundColor: "#F7F7F7",
    },
    listactions: { 
        backgroundColor: "#FFF", 
        borderRadius: "0px 0px 5px 5px", 
        justifyContent: "center"
    },
    listactionsaction: {
        marginLeft: "15px",
        position: "relative",
        top: "5px",
        cursor: "pointer"
    },
    discountaction: {
        backgroundColor: "#DDDDDD",
        color: "#999999",
        width: "30px",
        height: "30px",
        borderRadius: "15px",
        padding: "5px 0px",
        border: "none"
    },
    modal: {
		overlay: {
            backgroundColor: "rgba(0,0,0,.5)",
            overflowY: "auto"
		},
		content: {
            margin: "0px auto",
            padding: "0px",
            maxWidth: "400px",
            minWidth: "300px",
		}
    },
    user: {
        display: "flex",
        flexDirection: "row"
    },
    avatar: {
        display: "block",
		width: "28px",
		height: "28px",
		borderRadius: "20px",
		backgroundColor: "#DDDDDD",
        backgroundSize: "cover",
        backgroundPosition: "center",
		marginLeft: "15px",
    },
    discount: {
        fontSize: "20px",
        fontWeight: "500",
        color: "#999999",
        padding: "0px",
        background: "transparent",
        border: "none",
        width: "25px"
    }
}

const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const percent = (cost, amount) => {
    if(cost == 0 || amount == 0){
        return 0
    } else {
        //return Math.abs(((cost - amount) / cost) * 100).toFixed(0)
        return Math.abs(((1 - (cost / amount)) * 100)).toFixed(0)
    }
}

const groupby = function(xs, key) {
	return xs.reduce(function(rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
};

export default withRouter(Project);

import React from "react";
import { Link, Route } from "react-router-dom";
import { FirestoreCollection } from 'react-firestore';
import * as firebase from 'firebase';
import MDSpinner from 'react-md-spinner';
import Countries from '../subsidiaries/Countries.js';
import CurrencyInput from 'react-currency-masked-input'

class EditItem extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
            working: true,
            selected: false,
            search: "",
            items: [],
            id: null,
            department: "",
            category: "",
            brand: "",
            model: "",
            supplier: null,
            description: "",
            /*name: "",
            email: "",
            phone: "",
            address: "",
            postalcode: "",
            city: "",
            state: "",
            country: "Brasil",*/
            cost: 0,
            amount: 0,
            quantity: 1,
            measure: "unit",
            status: "",
            code: "",
            //states: [{"name": "Acre"},{"name": "Alagoas"},{"name": "Amapá"},{"name": "Amazonas"},{"name": "Bahia"},{"name": "Ceará"},{"name": "Distrito Federal"},{"name": "Espírito Santo"},{"name": "Goiás"},{"name": "Maranhão"},{"name": "Mato Grosso"},{"name": "Mato Grosso do Sul"},{"name": "Minas Gerais"},{"name": "Pará"},{"name": "Paraíba"},{"name": "Paraná"},{"name": "Pernambuco"},{"name": "Piauí"},{"name": "Rio de Janeiro"},{"name": "Rio Grande do Norte"},{"name": "Rio Grande do Sul"},{"name": "Rondônia"},{"name": "Roraima"},{"name": "Santa Catarina"},{"name": "São Paulo"},{"name": "Sergipe"},{"name": "Tocantins"}],
        };
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
        this.saveItem = this.saveItem.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentDidMount(){
        if(this.props.id !== "new"){
            this.setState({
                working: false,
                id: this.props.project.list.find((i) => i.id == this.props.id).id,
                category: this.props.project.list.find((i) => i.id == this.props.id).category,
                department: this.props.project.list.find((i) => i.id == this.props.id).department,
                brand: this.props.project.list.find((i) => i.id == this.props.id).brand,
                model: this.props.project.list.find((i) => i.id == this.props.id).model,
                supplier: this.props.project.list.find((i) => i.id == this.props.id).supplier,
                description: this.props.project.list.find((i) => i.id == this.props.id).description,
                /*name: this.props.project.list[this.props.id].supplier ? this.props.project.list[this.props.id].supplier.name : "",
                email: this.props.project.list[this.props.id].supplier ? this.props.project.list[this.props.id].supplier.email : "",
                phone: this.props.project.list[this.props.id].supplier ? this.props.project.list[this.props.id].supplier.phone : "",
                address: this.props.project.list[this.props.id].supplier ? this.props.project.list[this.props.id].supplier.location ? this.props.project.list[this.props.id].supplier.location.address : "" : "",
                postalcode: this.props.project.list[this.props.id].supplier ? this.props.project.list[this.props.id].supplier.location ? this.props.project.list[this.props.id].supplier.location.postalcode : "" : "",
                city: this.props.project.list[this.props.id].supplier ? this.props.project.list[this.props.id].supplier.location ? this.props.project.list[this.props.id].supplier.location.city : "" : "",
                state: this.props.project.list[this.props.id].supplier ? this.props.project.list[this.props.id].supplier.location ? this.props.project.list[this.props.id].supplier.location.state : "" : "",
                country: this.props.project.list[this.props.id].supplier ? this.props.project.list[this.props.id].supplier.location?  this.props.project.list[this.props.id].supplier.location.country : "" : "",*/
                cost: this.props.project.list.find((i) => i.id == this.props.id).cost,
                amount: this.props.project.list.find((i) => i.id == this.props.id).amount,
                quantity: this.props.project.list.find((i) => i.id == this.props.id).quantity,
                measure: this.props.project.list.find((i) => i.id == this.props.id).measure,
                status: this.props.project.list.find((i) => i.id == this.props.id).status,
                code: this.props.project.list.find((i) => i.id == this.props.id).code.value,
            })
        } else {
            this.setState({
                working: false
            });
        }
        firebase.firestore().collection('items').get().then((items) => {
            this.setState({
                items: items.docs,
                //listed: items.docs
            })
        })
    }

    saveItem(){
        this.setState({
            working: true
        })
        if(this.state.category !== "" && this.state.brand !== "" && this.state.model !== "" && this.state.amount !== "" && this.state.measure !== ""){
            var payload = {
                "department": this.state.department,
                "category": this.state.category,
                "brand": this.state.brand,
                "model": this.state.model,
                "cost": parseInt(this.state.cost.toString().replace(".", "")),
                "amount": parseInt(this.state.amount.toString().replace(".", "")),
                "measure": this.state.measure,
                "code": {
                    "type": "ean13",
                    "value": this.state.code
                },
                "supplier": this.state.supplier
            }
            /*if(this.props.supplier != null || (this.props.name != "" && this.props.email != "")){
                payload.supplier = {
                    "name": this.state.name,
                    "email": this.state.email,
                    "phone": parseInt(this.state.phone),
                    "location": {
                        "address": this.state.address,
                        "postalcode": parseInt(this.state.postalcode),
                        "city": this.state.city,
                        "state": this.state.state,
                        "country": this.state.country
                    },
                }
            }*/
            firebase.firestore().collection('items').add(payload).then((returned) => {
                this.setState({
                    id: returned.id
                }, () => {
                    this.save();
                })
            }).catch((e) => {
                console.error(e);
                alert("Ocorreu um erro ao salvar novo item.")
                this.setState({
                    working: false
                })
            })
        } else {
            alert("Preencha os campos obrigatórios (*) para continuar.")
        }
    }

    save(){
        if(this.state.amount !== ""){
            this.setState({
                working: true
            })
            var data = {
                "id": this.state.id,
                "department": this.state.department,
                "category": this.state.category,
                "brand": this.state.brand,
                "model": this.state.model,
                "supplier": null,
                "description": this.state.description ? this.state.description : "",
                "cost": parseInt(this.state.cost.toString().replace(".", "")),
                "amount": parseInt(this.state.amount.toString().replace(".", "")),
                "quantity": parseInt(this.state.quantity),
                "measure": this.state.measure,
                "status": this.state.status,
                "code": {
                    "type": "ean13",
                    "value": this.state.code
                },
                "supplier": this.state.supplier
            }
            /*if(this.props.name != "" && this.props.email != ""){
                data.supplier = {
                    "name": this.state.name,
                    "email": this.state.email,
                    "phone": parseInt(this.state.phone),
                    "location": {
                        "address": this.state.address,
                        "postalcode": parseInt(this.state.postalcode),
                        "city": this.state.city,
                        "state": this.state.state,
                        "country": this.state.country
                    },
                }
            }*/
            let list = this.props.project.list
            if(this.props.id === "new"){
                list.push(data)
                var payload = {
                    "list": list,
                    "updated": new Date()
                }
                firebase.firestore().collection('projects').doc(this.props.project.id).set(payload, { merge: true }).then(() => {
                    firebase.firestore().collection('messages').add({ "project": this.props.project.id, "title": this.props.project.client.name, "message": this.props.profile.name + " adicionou " + data.model + " ao projeto", "created": new Date() })
                    this.setState({
                        working: false
                    }, () => {
                        this.close();
                    })
                }).catch((e) => {
                    console.error(e);
                    alert("Ocorreu um erro ao salvar novo item.")
                    this.setState({
                        working: false
                    })
                })
            } else {
                var index = list.findIndex((i) => i.id == this.props.id);
                list[index] = data
                var payload = {
                    "list": list,
                    "updated": new Date()
                }
                firebase.firestore().collection('projects').doc(this.props.project.id).set(payload, { merge: true }).then(() => {
                    firebase.firestore().collection('messages').add({ "project": this.props.project.id, "title": this.props.project.client.name, "message": this.props.profile.name + " atualizou os dados de " + data.model, "created": new Date() })
                    this.setState({
                        working: false
                    }, () => {
                        this.close();
                    })
                }).catch((e) => {
                    console.error(e);
                    alert("Ocorreu um erro ao salvar novo item.")
                    this.setState({
                        working: false
                    })
                })
            }
            if(this.state.supplier !== null){
                // salvar supplier
            }
        } else {
            alert("Preencha os campos obrigatórios (*) para continuar.")
        }
    }

    delete(){
        if(window.confirm("Deseja deletar este item do projeto?")){
            let list = this.props.project.list;
            var index = list.findIndex((i) => i.id == this.props.id);
            let model = this.props.project.list[index].model;
            list.splice(index, 1);
            var payload = {
                "list": list,
                "updated": new Date()
            }
            firebase.firestore().collection('projects').doc(this.props.project.id).set(payload, { merge: true }).then(() => {
                firebase.firestore().collection('messages').add({ "project": this.props.project.id, "title": this.props.project.client.name, "message": this.props.profile.name + " deletou " + model + " do projeto", "created": new Date() })
                this.setState({
                    working: false
                }, () => {
                    this.close();
                })
            }).catch((e) => {
                console.error(e);
                alert("Ocorreu um erro ao deletar item.")
                this.setState({
                    working: false
                })
            })
        }
    }

    close(){
        this.props.close();
    }

    select(data){
        //console.log(data)
        this.setState({
            working: false,
            selected: true,
            id: data.id,
            department: data.data().department,
            category: data.data().category,
            brand: data.data().brand,
            model: data.data().model,
            supplier: data.data().supplier,
            description: data.data().description,
            /*name: data.data().supplier ? data.data().supplier.name : "",
            email: data.data().supplier ? data.data().supplier.email : "",
            phone: data.data().supplier ? data.data().supplier.phone : "",
            address: data.data().supplier ? data.data().supplier.location ? data.data().supplier.location.address : "" : "",
            postalcode: data.data().supplier ? data.data().supplier.location ? data.data().supplier.location.postalcode : "" : "",
            city: data.data().supplier ? data.data().supplier.location ? data.data().supplier.location.city : "" : "",
            state: data.data().supplier ? data.data().supplier.location ? data.data().supplier.location.state : "" : "",
            country: data.data().supplier ? data.data().supplier.location? data.data().supplier.location.country : "" : "",*/
            cost: data.data().cost,
            amount: data.data().amount,
            measure: data.data().measure,
            code: data.data().code.value,
        })
    }

	render() {
		return (
			<div style={styles.body}>
                <div style={styles.header}>
                    <h3 style={styles.title}>{this.state.id != null ? (this.state.category + " " + this.state.brand)  : "Item"}</h3>
                    {/*this.props.id === "new" && this.state.id === null ? <button style={{...styles.button, ...styles.white}} type="button" onClick={() => this.setState({ id: "new" })}>NOVO</button> : null*/}
                </div>
                {this.state.id != null ? <form style={styles.form}>
                    {/*<hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Categoria</label>
                        <input type="text" placeholder="Receiver" style={styles.input} value={this.state.category} onChange={(e) => this.setState({ category: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Marca</label>
                        <input type="text" placeholder="Sony" style={styles.input} value={this.state.brand} onChange={(e) => this.setState({ brand: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Modelo</label>
                        <input type="text" placeholder="AXU-1221" style={styles.input} value={this.state.model} onChange={(e) => this.setState({ model: e.target.value })}/>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Descrição</label>
                        <textarea style={styles.input} value={this.state.description} onChange={(e) => this.setState({ description: e.target.value })}></textarea>
                    </div>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Código</label>
                        <input type="text" placeholder="789299493" style={styles.input} value={this.state.code} onChange={(e) => this.setState({ code: e.target.value })}/>
                    </div>*/}
                    {this.props.profile.role === "superadmin" || this.props.profile.role === "admin" ? <div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Custo</label>
                            <CurrencyInput placeholder="1500.00" style={styles.input} defaultValue={(this.state.cost / 100).toFixed(2).toString()} onChange={(e) => this.setState({ cost: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Valor *</label>
                            <CurrencyInput placeholder="1500.00" style={styles.input} defaultValue={(this.state.amount / 100).toFixed(2).toString()} onChange={(e) => this.setState({ amount: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Quantidade ({this.getMeasureName(this.state.measure)})</label>
                            <input type="number" placeholder="1" style={styles.input} value={this.state.quantity} onChange={(e) => this.setState({ quantity: e.target.value })}/>
                        </div>
                    </div> : null}
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Status</label>
                        <select style={styles.select} value={this.state.status} onChange={(e) => this.setState({ status: e.target.value })}>
                            <option value={""}>Listado</option>
                            <option value="quoted">Solicitado</option>
                            <option value="bought">Comprado</option>
                            <option value="delivered">Entregue</option>
                            <option value="installed">Instalado</option>
                        </select>
                    </div>
                    <hr/>
                    {/*
                    <div style={styles.field}>
                        <label style={styles.label}>Medida</label>
                        <select style={styles.select}  value={this.state.measure} onChange={(e) => this.setState({ measure: e.target.value })}>
                            <option value="unit">Unidade</option>
                            <option value="pair">Par</option>
                            <option value="meter">Metro</option>
                            <option value="hour">Hora</option>
                        </select>
                    </div>
                    
                    
                    <hr/>*/}
                    {/*this.state.id === "new" ? <div style={styles.supplier}>
                        <div style={styles.field}>
                            <label style={styles.label}>Nome do fornecedor</label>
                            <input type="text" placeholder="Nome" style={styles.input} value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>E-mail</label>
                            <input type="email" placeholder="nome@email.com" style={styles.input} value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Telefone</label>
                            <input type="number" placeholder="99 9999 9999" style={styles.input} value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Endereço</label>
                            <input type="text" placeholder="Rua 10" style={styles.input} value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>CEP</label>
                            <input type="number" placeholder="999999 999" style={styles.input} value={this.state.postalcode} onChange={(e) => this.setState({ postalcode: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Cidade</label>
                            <input type="text" placeholder="Cidade" style={styles.input} value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Estado</label>
                            {this.state.country == "Brasil" ? <select style={styles.select} value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })}>
                                {
                                    this.state.states.map((state, i) => {
                                        return <option value={state.name} key={i}>{state.name}</option>
                                    })
                                }
                            </select> : <input type="text" placeholder="Nome do estado" style={styles.input} value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })}/> }
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>País</label>
                            <select style={styles.select} value={this.state.country} onChange={(e) => this.setState({ country: e.target.value })}>
                                {
                                    Countries.map((country, i) => {
                                        return <option value={country.nome_pais} key={i}>{country.nome_pais}</option>
                                    })
                                }
                            </select>
                        </div>
                        <hr/>
                            </div> : null*/}
                    <div style={styles.actions}>
                        <button style={{...styles.button, ...styles.alt}} type="button" onClick={this.close}>CANCELAR</button>
                        {this.props.id !== null && this.state.selected === false && (this.props.profile.role === "superadmin" || this.props.profile.role === "admin") ? <button style={{...styles.button, ...styles.delete}} type="button" onClick={this.delete}>DELETAR</button> : null}
                        {this.state.working ? <MDSpinner style={{ marginLeft: "15px" }} singleColor="#32e100" size="24"/> : <button style={styles.button} onClick={this.state.id === "new" ? this.saveItem : this.save} type="button">SALVAR</button>}
                    </div>
                </form> : <form style={styles.form}>
                    <hr/>
                    <div style={styles.field}>
                        <label style={styles.label}>Buscar no catálogo</label>
                        <input type="text" placeholder="Modelo, categoria ou marca" style={styles.input} value={this.state.search} onChange={(e) => this.setState({ search: e.target.value })}/>
                    </div>
                    <hr/>
                    <div><br/></div>
                    {/*<FirestoreCollection
                        path="items"
                        filter={this.state.search.length > 0 ? ['model', '==', this.state.search] : null}
						render={({isLoading, error, data}) => this.renderItems(isLoading, error, data)}
                    />*/}
                    {this.state.items.length ? this.state.items.filter((i) => i.data().category.toLowerCase().search(this.state.search.toLowerCase()) >= 0 || i.data().brand.toLowerCase().search(this.state.search.toLowerCase()) >= 0 || i.data().model.toLowerCase().search(this.state.search.toLowerCase()) >= 0 || i.data().department.toLowerCase().search(this.state.search.toLowerCase()) >= 0).map((item, i) => {
                        return <a onClick={() => this.select(item)} key={i}>
                            <div style={styles.field} key={item.id}>
                                <p style={styles.item}>{item.data().category} {item.data().brand} {item.data().model}</p>
                            </div>
                            <hr/>
                        </a>
                    }) : null}
                </form>}
            </div>
		);
    }

    getMeasureName(measure){
        switch(measure){
            case "unit":
            return "Unidade";

            case "pair":
            return "Par";

            case "meter":
            return "Metro";

            case "hour":
            return "Hora"
        }
    }
}

const styles = {
	body: {
        
    },
    header: {
        height: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        backgroundColor: "#CCCCCC",
        color: "#999999",
    },
    title: {
        fontSize: "16px",
        textTransform: "uppercase",
        fontWeight: "300",
        margin: "0px",
        color: "#FFFFFF"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "50px"
    },
    field: {
        position: "relative",
    },
    label: {
        position: "absolute",
        top: "-7px",
        left: "10px",
        fontSize: "10px",
        padding: "0px 5px",
        backgroundColor: "#FFFFFF",
        color: "#CCCCCC",
        textTransform: "uppercase"
    },
    input: {
        border: "none",
        padding: "15px",
        paddingBottom: "20px",
        width: "100%"
    },
    select: {
        border: "none",
        margin: "15px 8px",
        paddingBottom: "20px",
        width: "calc(100% - 30px)",
        backgroundColor: "#FFFFFF"
    },
    actions: {
        padding: "15px",
        textAlign: "right"
    },
    supplier: {
        
    },
    button: {
		backgroundColor: "rgba(50, 225, 0, 1.0)",
		color: "#FFF",
		padding: "5px 13px",
		borderRadius: "22px",
        fontSize: "12px",
        fontWeight: 300,
        border: "none",
        marginLeft: "15px"
    },
    alt: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		color: "#999999",
    },
    delete: {
		backgroundColor: "#ff6868",
		color: "#FFFFFF",
    },
    white: {
		backgroundColor: "#FFFFFF",
		color: "#999999",
    },
    item: {
        margin: "0px",
        padding: "15px"
    }
}

export default EditItem;

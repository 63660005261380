import React from "react";
import { Link, Route } from "react-router-dom";
import { FirestoreCollection } from 'react-firestore';
import MDSpinner from 'react-md-spinner';
import Modal from 'react-modal';
import Subsidiary from './Subsidiary';
import EditSubsidiary from '../subsidiaries/EditSubsidiary.js';

class Subsidiaries extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			subsidiary: false,
			search: ""
		};
	}

	renderSub(isLoading, error, data){
		if(isLoading){
			return <div style={styles.loading}><MDSpinner style={{ marginLeft: "15px" }} singleColor="#999999" size="24"/></div>
		} else {
			return ((this.state.search.length > 0 ? data.filter((i) => i.name.search(this.state.search) >= 0) : data).map((sub, i) => {
				return <Link to={"/subsidiaries/" + sub.id} style={{...styles.item, ...(this.props.location.pathname === ("/subsidiaries/" + sub.id) ? styles.active : {})}} key={i}>
					<div>
						<p style={styles.name}>{sub.location.city}, {sub.location.state}</p>
					</div>
				</Link>
			}));
		}
	}
	render() {
		return (
			<div style={{...styles.body, ...(this.props.width < 767 ? { width: "100%" } : { width: this.props.width - 71 })}}>
				<div style={this.props.width < 767 ? { width: this.props.width } : { width: "300px", borderRight: "1px solid #EDEDED", }}>
					<div style={styles.search}>
						<input type="text" style={styles.input} value={this.state.search} placeholder="Buscar" onChange={(e) => this.setState({ search: e.target.value })}/>
						<a style={styles.addaction} onClick={() => this.setState({ subsidiary: "new" })}>
                            <i className="material-icons">add</i>
                        </a>
					</div>
					<FirestoreCollection
						path="subsidiaries"
						render={({isLoading, error, data}) => this.renderSub(isLoading, error, data)}
					/>
				</div>
				<Route path="/subsidiaries/:id" render={(props) => {
					return <Subsidiary width={this.props.width} height={this.props.height} profile={this.props.profile} {...props}/>
				}} />
				<Modal isOpen={this.state.subsidiary ? true : false} style={styles.modal} onRequestClose={() => this.setState({ subsidiary: null })}>
					<EditSubsidiary id={this.state.subsidiary} close={() => this.setState({ subsidiary: null })}/>
				</Modal>
            </div>
		);
	}
}

const styles = {
	body: {
		display: "flex",
		flexDirection: "row",
	},
	search: {
		height: "58px",
		margin: "15px",
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		//fontSize: "14px",
		fontWeight: 300
	},
	input: {
		padding: "10px 20px",
		marginRight: "15px",
		backgroundColor: "transparent",
		borderRadius: "25px",
		border: "1px solid #EDEDED",
		color: "#555555",
	},
	item: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-start",
		alignItems: "center",
		borderBottom: "1px solid rgba(0,0,0,.05)",
		//borderRadius: "7px",
		//margin: "15px 15px 0px 15px",
		padding: "15px",
		fontWeight: 300,
		color: "#999999"
	},
	active: {
		backgroundColor: "#FFFFFF",
		color: "#555555"
	},
	badge: {
		backgroundColor: "#32e100FF",
		color: "#FFF",
		padding: "5px 10px",
		borderRadius: "16px",
		fontSize: "12px"
	},
	loading: {
		padding: "30px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	avatar: {
		width: "40px",
		height: "40px",
		borderRadius: "20px",
        backgroundColor: "#DDDDDD",
		backgroundSize: "cover",
		backgroundPosition: "center",
		marginRight: "15px"
	},
	name: {
		margin: "0px"
	},
	addaction: {
		marginRight: "10px",
		display: 'flex',
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "center"
	},
	modal: {
		overlay: {
            backgroundColor: "rgba(0,0,0,.5)",
            overflowY: "auto"
		},
		content: {
            margin: "0px auto",
            padding: "0px",
            maxWidth: "400px",
            minWidth: "300px",
		}
	}
}

export default Subsidiaries;

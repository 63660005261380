import React from "react";
import { Link, withRouter } from "react-router-dom";
import * as firebase from 'firebase';
import { FirestoreCollection } from 'react-firestore';
import MDSpinner from 'react-md-spinner';
import Countries from '../subsidiaries/Countries.js';
import MaskedInput from 'react-maskedinput'

var subsidiaries = [];

class EditUser extends React.Component {
	constructor(props) {
		super(props);
		this.state = { 
            working: true,
            name: "",
            email: "",
            phone: "",
            subsidiary: "",
            role: "supplier",
            birthdate: "",
            gender: "",
            valid: false,
            address: "",
            postalcode: "",
            city: "",
            state: "",
            country: "Brasil",
            states: [{"name": "Acre"},{"name": "Alagoas"},{"name": "Amapá"},{"name": "Amazonas"},{"name": "Bahia"},{"name": "Ceará"},{"name": "Distrito Federal"},{"name": "Espírito Santo"},{"name": "Goiás"},{"name": "Maranhão"},{"name": "Mato Grosso"},{"name": "Mato Grosso do Sul"},{"name": "Minas Gerais"},{"name": "Pará"},{"name": "Paraíba"},{"name": "Paraná"},{"name": "Pernambuco"},{"name": "Piauí"},{"name": "Rio de Janeiro"},{"name": "Rio Grande do Norte"},{"name": "Rio Grande do Sul"},{"name": "Rondônia"},{"name": "Roraima"},{"name": "Santa Catarina"},{"name": "São Paulo"},{"name": "Sergipe"},{"name": "Tocantins"}],
            roles: [
                {
                    "key": "staff",
                    "name": "Staff"
                }
            ]
        };
        this.close = this.close.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount(){
        if(this.props.id !== "new"){
            firebase.firestore().collection('profiles').doc(this.props.id).get().then((profile) => {
                this.setState({
                    working: false,
                    name: profile.data().name,
                    email: profile.data().email,
                    phone: profile.data().phone,
                    subsidiary: profile.data().subsidiary ? profile.data().subsidiary.id : "",
                    role: profile.data().role,
                    birthdate: profile.data().birthdate,
                    gender: profile.data().gender,
                    valid: profile.data().valid,
                    address: profile.data().location ? profile.data().location.address : "",
                    postalcode: profile.data().location ? profile.data().location.postalcode : "",
                    city: profile.data().location ? profile.data().location.city : "",
                    state: profile.data().location ? profile.data().location.state : "",
                    country: profile.data().location ? profile.data().location.country : ""
                })
            });
        } else {
            this.setState({
                working: false,
                role: "supplier",
            })
        }
        if(this.props.profile.role === "superadmin"){
            let temp = this.state.roles;
            temp.push({
                "key": "admin",
                "name": "Admin"
            })
            this.setState({
                roles: temp
            })
        }
    }

    save(){
        this.setState({
            working: true
        }, () => {
            if(this.state.role !== "supplier"){
                var payload = {
                    "role": this.state.role,
                }
                if(this.props.profile.role === "superadmin"){
                    payload["subsidiary"] = {
                        "id": this.state.subsidiary,
                        "name": subsidiaries.find((i) => i.id === this.state.subsidiary).legal.name
                    }
                } else {
                    payload["subsidiary"] = {
                        "id": this.props.profile.subsidiary.id,
                        "name": this.props.profile.subsidiary.name
                    }
                }
                firebase.firestore().collection('profiles').doc(this.props.match.params.id).set(payload, { merge: true }).then(() => {
                    this.setState({
                        working: false
                    }, () => {
                        this.close();
                    })
                }).catch((e) => {
                    console.error(e);
                    alert("Ocorreu um erro ao salvar usuário.")
                    this.setState({
                        working: false
                    })
                })
            } else {
                var payload = {
                    "name": this.state.name,
                    "email": this.state.email,
                    "phone": this.state.phone,
                    "role": this.state.role,
                    "valid": this.state.valid,
                    "location": {
                        "address": this.state.address,
                        "postalcode": this.state.postalcode,
                        "city": this.state.city,
                        "state": this.state.state,
                        "country": this.state.country
                    }
                }
                if(this.props.id !== "new"){
                    firebase.firestore().collection('profiles').doc(this.props.id).set(payload, { merge: true }).then(() => {
                        this.setState({
                            working: false
                        }, () => {
                            this.close();
                        })
                    }).catch((e) => {
                        console.error(e);
                        alert("Ocorreu um erro ao salvar fornecedor.")
                        this.setState({
                            working: false
                        })
                    })
                } else {
                    firebase.firestore().collection('profiles').add(payload).then(() => {
                        this.setState({
                            working: false
                        }, () => {
                            this.close();
                        })
                    }).catch((e) => {
                        console.error(e);
                        alert("Ocorreu um erro ao salvar fornecedor.")
                        this.setState({
                            working: false
                        })
                    })
                }
            }
        })
        
    }

    close(){
        this.props.close();
    }

	render() {
		return (
			<div style={styles.body}>
                <div style={styles.header}>
                    <h3 style={styles.title}>{this.state.role === "supplier" ? "Fornecedor" : "Usuário"}</h3>
                </div>
                <form style={styles.form}>
                    {this.state.role !== "supplier" ? <React.Fragment>
                        {this.props.profile.role === "superadmin" ? <div>
                            <hr/>
                            <div style={styles.field}>
                                <label style={styles.label}>Filial</label>
                                <FirestoreCollection
                                    path="subsidiaries"
                                    render={({isLoading, error, data}) => {
                                        subsidiaries = data;
                                        return <select style={styles.select} value={this.state.subsidiary} onChange={(e) => this.setState({ subsidiary: e.target.value })}>
                                            <option value={""}>{"Sem filial"}</option>
                                            {
                                                data.map((item, i) => {
                                                    return <option value={item.id} key={i}>{item.legal.name}</option>
                                                })
                                            }
                                        </select>
                                    }}
                                />
                            </div>
                        </div> : null}
                        
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Credencial</label>
                            <select style={styles.select} value={this.state.role ? this.state.role : ""} onChange={(e) => this.setState({ role: e.target.value })}>
                                <option value={""}>{"Sem credencial"}</option>
                                {
                                    this.state.roles.map((role, i) => {
                                        return <option value={role.key} key={i}>{role.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <hr/>
                    </React.Fragment> : <React.Fragment>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Nome</label>
                            <input type="text" style={styles.input} placeholder="Nome" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>E-mail</label>
                            <input type="text" style={styles.input} placeholder="nome@email.com" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Telefone</label>
                            <MaskedInput type="text" style={styles.input} mask="(11)11111-1111" size="13" value={this.state.phone.toString()} onChange={(e) => this.setState({ phone: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Endereço</label>
                            <input type="text" placeholder="Rua 10" style={styles.input} value={this.state.address} onChange={(e) => this.setState({ address: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>CEP</label>
                            <MaskedInput type="text" style={styles.input} mask="11111-111" size="9" value={this.state.postalcode.toString()} onChange={(e) => this.setState({ postalcode: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Cidade</label>
                            <input type="text" placeholder="Cidade" style={styles.input} value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })}/>
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>Estado</label>
                            {this.state.country == "Brasil" ? <select style={styles.select} value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })}>
                                {
                                    this.state.states.map((state, i) => {
                                        return <option value={state.name} key={i}>{state.name}</option>
                                    })
                                }
                            </select> : <input type="text" placeholder="Nome do estado" style={styles.input} value={this.state.state} onChange={(e) => this.setState({ state: e.target.value })}/> }
                        </div>
                        <hr/>
                        <div style={styles.field}>
                            <label style={styles.label}>País</label>
                            <select style={styles.select} value={this.state.country} onChange={(e) => this.setState({ country: e.target.value })}>
                                {
                                    Countries.map((country, i) => {
                                        return <option value={country.nome_pais} key={i}>{country.nome_pais}</option>
                                    })
                                }
                            </select>
                        </div>
                        <hr/>
                    </React.Fragment>}
                    
                    
                    <div style={styles.actions}>
                        <button style={{...styles.button, ...styles.alt}} type="button" onClick={this.close}>CANCELAR</button>
                        {this.state.working ? <MDSpinner style={{ marginLeft: "15px" }} singleColor="#32e100" size="24"/> : <button style={styles.button} onClick={this.save} type="button">SALVAR</button>}
                    </div>
                </form>
            </div>
		);
	}
}

const styles = {
	body: {
        
    },
    header: {
        height: "55px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 15px",
        backgroundColor: "#CCCCCC",
        color: "#999999",
    },
    title: {
        fontSize: "16px",
        textTransform: "uppercase",
        fontWeight: "300",
        margin: "0px",
        color: "#FFFFFF"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "50px"
    },
    field: {
        position: "relative",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        height: "60px"
    },
    label: {
        position: "absolute",
        top: "-7px",
        left: "10px",
        fontSize: "10px",
        padding: "0px 5px",
        backgroundColor: "#FFFFFF",
        color: "#CCCCCC",
        textTransform: "uppercase"
    },
    input: {
        border: "none",
        padding: "15px",
        paddingBottom: "20px",
        width: "100%"
    },
    select: {
        border: "none",
        margin: "15px 8px",
        paddingBottom: "20px",
        width: "calc(100% - 30px)",
        backgroundColor: "#FFFFFF"
    },
    actions: {
        padding: "15px",
        textAlign: "right"
    },
    button: {
		backgroundColor: "rgba(50, 225, 0, 1.0)",
		color: "#FFF",
		padding: "5px 13px",
		borderRadius: "22px",
        fontSize: "12px",
        fontWeight: 300,
        border: "none",
        marginLeft: "15px"
    },
    alt: {
		backgroundColor: "rgba(0, 0, 0, 0.1)",
		color: "#999999",
    },
    refresh: {
        border: "none",
        backgroundColor: "transparent",
        height: "30px"
    }
}

export default withRouter(EditUser);
